import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="recaptcha"
export default class extends Controller {
  static values = {
    siteKey: String,
    widgetId: Number,
  }

  static targets = [
    'holder',
  ]

  callbackFunctionName() {
    // Unique name, so works if have multiple recaptcha forms in same DOM.
    return `${this.element.id}_invisible_recaptcha_submit_form`;
  }

  connect() {
    const { id } = this.element;
    window[id].recaptchaCompleted = false;
    window[id].recaptchaForm = this.element;

    // Define globally-available callback function for recaptcha to call once completed.
    window[this.callbackFunctionName()] = () => {
      window[id].recaptchaCompleted = true;
      // Note: requestSubmit() simulates a button click, so turbo will still kick in.
      const form = window[id].recaptchaForm;
      form.requestSubmit();
    };

    this.render(this.holderTarget);
  }

  render(element) {
    // We may have a race condition between the script tag loading vs this controller.
    // For that reason - check grecaptcha method is available, and if not, wait for it.
    if ((window.grecaptcha === undefined) || (window.grecaptcha.render === undefined)) {
      setTimeout(() => {
        this.render(element);
      }, 250);
      return;
    }

    // Assign reCaotcha widget id to value, so know which to execute in case DOM has more than one.
    this.widgetIdValue = window.grecaptcha.render(
      element,
      {
        sitekey: this.siteKeyValue,
        class: 'g-recaptcha',
        callback: this.callbackFunctionName(),
        size: 'invisible',
      },
    );
  }

  /* eslint class-methods-use-this: "off" */
  execute(event) {
    const { id } = this.element;
    if (window[id].recaptchaCompleted === true) { return; }

    event.preventDefault();
    window.grecaptcha.execute(this.widgetIdValue);
  }
}
