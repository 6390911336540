import Glide from '@glidejs/glide';

class UspsSlider {
  constructor({ selector }) {
    this.selector = document.querySelector(selector);

    if (!this.selector) { return null; }

    var perViewValue = 5;
    var focusAtValue = 'center';

    if (this.selector.hasAttribute('data-js-usp-module')) {
      perViewValue = 4;
      focusAtValue = 1;
    }

    // console.log("USPS slider exists, run slider")
    const slides = this.selector.querySelectorAll('.glide__slide');

    if (slides.length > 1) {
      this.mountSlider(this.selector, slides, perViewValue, focusAtValue);
    } else { // There is one slide or less
      // Add active class to the only slide so the caption animation still works
      let onlyChildSlide = this.selector.querySelector('.glide__slide');
      let arrowNav = this.selector.querySelector('[data-glide-el="controls"]');

      if (arrowNav) {
        arrowNav.style.display = 'none';
      }

      setTimeout(() => {
        onlyChildSlide.classList.add('glide__slide--active');
      }, 300);
    }

    this.vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    this.uspsCarouselBreakpoint = 960; // px value to re-init the carousel if needed
    this.viewportWidthThreshold = '';

    this.viewportWidthChecker();
    this.resizeWindowEvents();
  }

  mountSlider(selector, slides, perViewValue, focusAtValue) {
    if (!this.selector) { return; }

    var nextLastSlide;
    var lastSlideIndex = slides.length - 1;

    this.uspsSlider = new Glide(selector, {
      type: 'carousel',
      gap: 0,
      animationDuration: 1000,
      autoplay: false,
      focusAt: focusAtValue,
      autoheight: true,
      hoverpause: false,
      swipeThreshold: false,
      dragThreshold: false,
      perView: perViewValue,
      breakpoints: {
        959: {
          perView: 2,
          autoplay: 3000,
          focusAt: 'center',
        },
        600: {
          perView: 1,
          autoplay: 3000,
          focusAt: 'center',
        }
      }
    })

    this.uspsSlider.on('move', (e,f) => {
      if (this.uspsSlider.index > 0) {
        // Any slide other than the first

        // Add class to start opacity/fade animation
        slides[this.uspsSlider.index].classList.add('about-to-slide');

        // Select the previous slide and remove class for animation
        var previousSlideIndex = this.uspsSlider.index - 1;
        slides[previousSlideIndex].classList.remove('about-to-slide');

        // Select clone of first slide
        nextLastSlide = selector.querySelector('.glide__slide:not(.glide__slide--clone) + .glide__slide--clone');

        if (nextLastSlide) {
          // Make sure class is removed off first slide clone
          nextLastSlide.classList.remove('about-to-slide');
        }

        // On the last slide
        if (this.uspsSlider.index == lastSlideIndex) {
          slides[previousSlideIndex].classList.remove('about-to-slide');
        }
      } else {
        // On the first slide
        slides[this.uspsSlider.index].classList.add('about-to-slide');

        // Won't exist first time around
        if (nextLastSlide) {
          // Add class to first slide clone
          // It is shown until the animtion back to the first slide is complete
          nextLastSlide.classList.add('about-to-slide');
        }
        // Target the last slide and remove the class
        slides[lastSlideIndex].classList.remove('about-to-slide');
      }
    });

    this.uspsSlider.mount();
  }

  // Check width of the viewport
  /////////////////////////////////////////////////
  viewportWidthChecker() {
    // if viewport is greater than the navigationBreakpoint
    if(this.vw >= this.uspsCarouselBreakpoint) {
      if (this.viewportWidthThreshold !== 'higher') {
        this.viewportWidthThreshold = 'higher';
        this.carouselToggleSwitch();
      }
    } else {
      if (this.viewportWidthThreshold !== 'lower') {
        this.viewportWidthThreshold = 'lower';
        this.carouselToggleSwitch();
      }
    }
  };

  // Switch to decide whether to pause or play
  /////////////////////////////////////////////////
  carouselToggleSwitch(){
    if (this.viewportWidthThreshold == 'higher') {
      // console.log("above");
      // Pause slider
      // Don't actually pause, discovered this can sometimes leave a slide off page
      // this.uspsSlider.pause();
    } else {
      // console.log("below");
      // Play slider
      this.uspsSlider.pause(); // need to pause first
      this.uspsSlider.play();
    }
  }

  resizeWindowEvents() {
    // if viewport is resized check whether we need to pause or play the slider
    window.addEventListener('resize', (e) => {
      this.vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
      // constantly run the VW checker as we resize the window
      this.viewportWidthChecker();
    }, {passive: true}); // set event listener to passive as we're not changing the default event behaviour of resize
  }
}

export default UspsSlider;
