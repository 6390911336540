import CountUp from './count-up';

// Note:  This should be run after TabbedInterface (for correct initial visibility)
class CountUps {
  constructor({ selector }) {
    this.targets = document.querySelectorAll(selector);
    if (!this.targets) { return null; }

    this.countups = [];
    this.targets.forEach((target) => {
      const { endVal } = target.dataset;
      const countup = new CountUp({
        target,
        endVal,
      });
      this.countups.push(countup);
    });

    this.initialise();
  }

  initialise() {
    this.counterVisibilityHandlers();

    // Handle window resize
    window.addEventListener('resize', () => {
      clearTimeout(this.resizeTimer);
      // Add small delay
      this.resizeTimer = setTimeout(() => {
        // Resizing has "stopped"
        this.counterVisibilityHandlers();
      }, 250);
    }, { passive: true });

    // Handle window scroll
    window.addEventListener('scroll', () => {
      this.counterVisibilityHandlers();
    }, { passive: true });

    // Handle tab clicks changing what's visible
    const tabButtons = document.querySelectorAll('[data-tab-button-for]');
    if (tabButtons) {
      tabButtons.forEach((tabButton) => {
        tabButton.addEventListener('click', () => {
          this.counterVisibilityHandlers();
        }, { passive: true });
      });
    }
  }

  counterVisibilityHandlers() {
    this.countups.forEach((countup) => {
      countup.counterVisibilityHandler();
    });
  }
}

export default CountUps;
