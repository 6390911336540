import Glide from '@glidejs/glide';
import Helpers from '../lib/helpers';

class GallerySlider {
  constructor({ selector }) {
    this.selector = document.querySelector(selector);
    if (!this.selector) { return null; }

    const slides = document.querySelectorAll('[data-js="gallery-slides"]');
    this.gallerySlides(slides);
  }

  initialiseModalSliders() {
    const slides = document.querySelectorAll('[data-js="modal-gallery-slides"]');
    if (slides.length === 0) { return false; }

    this.gallerySlides(slides);
    return true;
  }

  gallerySlides(slides) {
    Helpers.forEach(slides, (el) => {
      // Add nav bullets in before we mount so they have event listeners attached
      const glideTrack = el.querySelector('[data-glide-el="track"]');
      const bulletNav = el.querySelector('[data-glide-el="controls[nav]"]');

      // Add a nav bullet inside the container for each .glide__slide
      bulletNav.innerHTML = '';
      let trackChildrenIgnoringClones = glideTrack.firstElementChild.querySelectorAll('.glide__slide:not(.glide__slide--clone)');
      for (let i = 0; i < trackChildrenIgnoringClones.length; i += 1) {
        bulletNav.innerHTML
          += `<button class="glide__bullet" data-glide-dir="=${[i]}"><span></span></button>`;
      }

      // Note:  Not all sliders have id's specified - only those for which we need a named instance.
      const glideId = el.dataset.glideId || '';
      const glideInstanceName = `glide-${glideId}`;

      if (window[glideInstanceName]) {
        window[glideInstanceName].destroy();
      }

      window[glideInstanceName] = new Glide(el, {
        type: 'carousel',
        gap: 0,
        animationDuration: 200,
        autoplay: false,
        perView: 1,
        autoheight: true,
      }).mount();

      // Attach method to recalculate the arrows position once visible
      window[glideInstanceName].recalculateNavArrowPosition = () => {
        // Get the bullet nav container's height
        const bulletNavHeight = bulletNav.getBoundingClientRect().height;
        // console.log('bulletNavHeight = ', bulletNavHeight);
        // Center the arrows to the image
        const arrows = el.querySelector('[data-glide-el="controls"]');
        const distancePx = `${bulletNavHeight}px`;
        arrows.style.bottom = distancePx;
      };

      // Immmediately move the arrows
      GallerySlider.calculateNewArrowPosition(el);

      // And run again on resize
      window.addEventListener('resize', () => {
        GallerySlider.calculateNewArrowPosition(el);
      }, { passive: true });
    }, this);
  }

  static calculateNewArrowPosition(el) {
    // Select the bullet nav container
    const bulletNav = el.querySelector('[data-glide-el="controls[nav]"]');

    // Get it's height
    const bulletNavHeight = bulletNav.getBoundingClientRect().height;
    // console.log("bulletNavHeight = ", bulletNavHeight);

    // Center the arrows to the image
    const arrows = el.querySelector('[data-glide-el="controls"]');
    const distancePx = `${bulletNavHeight}px`;
    arrows.style.bottom = distancePx;
  }
}

export default GallerySlider;
