import Helpers from '../lib/helpers';

/* eslint-disable class-methods-use-this */
/* eslint no-underscore-dangle: "off" */

class ExitIntentModals {
  constructor() {
    let body = document.querySelector('body');

    /**
    * Add a class to body when its a touch device
    * This is needed for the modal system to open maps and videos directly on mobile
    * instead of opening them in a new modal window
    */
    // define boolean var as false initially as no touch has happened yet
    let isTouch = false;

    // add class to body if isTouch is true
    const checkTouchBoolean = () => {
      // this will only run once
      if (isTouch) {
        // console.log("is-touch class added");
        Helpers.addClass(body, 'is-touch-device');
        // class now added to body, so can remove the event listener
        window.removeEventListener('touchstart', touchStartInit, { passive: true });
      }
    };

    // touch device being used, set isTouch to true now
    let touchStartInit = () => {
      isTouch = true;
      checkTouchBoolean();
    };
    window.addEventListener('touchstart', touchStartInit, { passive: true });

    window.TriggerModals = this;
  }

  exitModals() {
    Helpers.forEach(document.querySelectorAll('[data-exit-modal-trigger]'), (el) => {
      // Setup our variables and select elements
      ////////////////////////////////////////////////////////
      const popup = el;
      const body = document.body;
      const visibleClassName = 'is-visible';
      const timeTriggerLength = 1500;
      const distanceTriggerPercentage = 50;
      let focusedElementBeforeModal;

      // Assign vars
      const close = el.querySelector('[data-js="popup-exit-intent-close"]');
      const modalTriggerType = el.getAttribute('data-exit-modal-trigger');
      const modalIdentifier = el.getAttribute('data-exit-modal-id');
      const modalExtraCssClasses = el.getAttribute('data-exit-modal-extra-css-classes');
      const cookieId = 'cc_exitintent_shown_id_' + modalIdentifier;
      // console.log("cookieId = ", cookieId);
      // console.log("modalTriggerType = ", modalTriggerType);

      // // Show the modal box & overlay
      // //////////////////////////////////////////
      let _show = function () {
        // Dispatch event, which will be listened for via Stimulus action in html - to show modal.
        // Reminder: Events are the 'correct' way for different pieces of code to communicate.
        // Using generic event name so this approach can be used elsewhere if/when required.
        const event = new CustomEvent('modalShowEvent', {
          bubbles: true,
          detail: {
            params: {
              type: 'scrape',
              scrapeSelector: '#exitIntentContent',
              modalCssClasses: `modal exit-intent is-visible ${modalExtraCssClasses}`,
            },
          },
        });
        window.dispatchEvent(event);
        // console.log('_show');

        createCookie(cookieId, 'true', 7);
      };


      // Check scroll velocity
      // Taken from: https://codepen.io/vsync/pen/taAGd/
      // https://stackoverflow.com/questions/22593286/detect-measure-scroll-speed/22599173
      /////////////////////////////////////////////////////////////////////////////////////
      var _checkScrollSpeed = (function(settings){
        settings = settings || {};

        var lastPos, newPos, timer, delta,
            delay = settings.delay || 50;

        function clear() {
          lastPos = null;
          delta = 0;
        }
        clear();

        return function(){
          newPos = window.scrollY;
          if ( lastPos != null ){
            delta = newPos -  lastPos;
          }
          lastPos = newPos;
          clearTimeout(timer);
          timer = setTimeout(clear, delay);
          return delta;
        };
      })();


      // Create a cookie
      /////////////////////////////////////
      function createCookie(name,value,days) {
        if (days) {
          var date = new Date();
          date.setTime(date.getTime()+(days*24*60*60*1000));
          var expires = "; expires="+date.toGMTString();
        }
        else var expires = "";
        document.cookie = name+"="+value+expires+"; path=/";
      }


      // Read a cookie
      /////////////////////////////////////
      function readCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
          var c = ca[i];
          while (c.charAt(0)==' ') c = c.substring(1,c.length);
          if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
      }


      // Time trigger functions
      /////////////////////////////////
      var _timedTrigger = function () {
        // Read cookie
        var cookieExists = readCookie(cookieId);

        // Wait for timer to finish
        setTimeout(function(){
          // console.log("run timed function");
          // Stop if there is a modal already open
          if (document.body.classList.contains('has-modal'))
          return;
          // If cookie doesn't already exist
          if(!cookieExists)
          _show();
        }, timeTriggerLength);
      }

      // Scroll distance trigger functions
      //////////////////////////////////////
      var _scrollDistanceTrigger = function () {
        var body = document.body;

        // set the width of the viewport on intial load
        var viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0),
            html = document.documentElement,
            height = Math.max( body.scrollHeight, body.offsetHeight,
                              html.clientHeight, html.scrollHeight, html.offsetHeight ),
            halfViewportHeight = viewportHeight / 2,
            dividingFactor = distanceTriggerPercentage / 100,
            scrollLength = (height * dividingFactor) - halfViewportHeight, // px distance before sticky kicks in
            modalVisible = false;
        // console.log("page height = ", height);
        // console.log("scrollLength = ", scrollLength);

        // Read cookie
        var cookieExists = readCookie(cookieId);

        window.addEventListener('scroll', function(){
          var scroll = window.pageYOffset;
          // console.log("scroll = ", scroll);

          if (scroll >= scrollLength) {
            // console.log("scroll distance reached");
            // Stop if there is a modal already open
            if (body.classList.contains('has-modal'))
            return;
            if(!cookieExists && !modalVisible) {
            // if(!cookieExists) {
              _show();
              modalVisible = true;
            }
          }
        });
      }

      // Exit intent trigger functions
      //////////////////////////////////////
      var _exitIntentTrigger = function () {
        // Must be attached to document.body for Firefox
        document.body.addEventListener('mouseout', function(e) {
          // Taken from: https://github.com/beeker1121/exit-intent-popup/blob/master/js/bioep.js
          e = e ? e : window.event;

          // If there is a modal already open
          if(body.classList.contains('has-modal'))
            return;

          // If this is an autocomplete element
          if(e.target.tagName.toString().toLowerCase() == "input")
            return;

          // If this is a select box (for Firefox)
          if(e.target.tagName.toString().toLowerCase() == "select")
            return;

          // Get the current viewport width.
          var vpWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

          // If the current mouse X position is within 50px of the right edge
          // of the viewport, return.
          if(e.clientX >= (vpWidth - 50))
            return;

          // If the current mouse Y position is not within 50px of the top
          // edge of the viewport, return.
          if(e.clientY >= 50)
            return;

          // Read cookie
          var cookieExists = readCookie(cookieId);

          // Reliable, works on mouse exiting window and
          // user switching active program
          var from = e.relatedTarget || e.toElement;

          // If we mouseout & cookie doesnt exist yet
          if(!from && !cookieExists)
            _show(); // Show the modal
        },{passive: true});

        // Check scroll velocity upwards
        window.addEventListener( 'scroll', function () {
          var cookieExists = readCookie(cookieId);
            // If scroll velocity is above threshold & cookie doesnt exist yet
            if (_checkScrollSpeed() < -200 && !cookieExists) {
              _show(); // Show the modal
            }
        },{passive: true});
      }

      // Remove inline min-height (half-pixel rendering fix) on resize of window
      //////////////////////////////////////////////////////////
      var _watchResize = function() {
        const modalBox = document.querySelector('.modal');

        if (modalBox.style.removeProperty) {
          modalBox.style.removeProperty('min-height');
        } else {
          modalBox.style.removeAttribute('min-height');
        }
      }

      // Timed trigger
      if (modalTriggerType == 'time') {
        _timedTrigger();
      }

      // Scroll distance trigger
      if (modalTriggerType == 'scroll') {
        _scrollDistanceTrigger();
      }

      // Exit-intent trigger
      if (modalTriggerType == 'exit') {
        _exitIntentTrigger();
      }
    });
  }
}

export default ExitIntentModals;
