import { Controller } from '@hotwired/stimulus';
import * as ModalContentLoaders from '../javascripts/application/modal-content-loaders';

// Connects to data-controller="modal"
export default class extends Controller {
  static targets = [
    'modalAndOverlayWrapper',
    'modal', // i.e. wrapper
    'modalBody',
    'modalHeader',
    'modalContent', // may have several
    'backLink',
    'overlay',
    'launcher',
    'close',
  ];

  /*
  Brief explanation of how this works:
  -----------------------------------

  If it's the first request - we prepare(i.e. add) the modal and overlay HTML.  This HTML is used
  to load content in to.
  Content is loaded into it's own holder (modalContentTarget).  If they then make another
  request - the same overall modal HTML is still used, but another modalContentTarget is added
  within - and the previous modalContentTarget is hidden, but still present in the DOM, so it's
  state remains as-is, for if/when they go back to it.
  The request to show content is stored against the modalContentTarget - in the DOM - so the DOM
  always holds it's own 'state' - which aligns with the StimulusJS approach in general.  This
  also neatly means that as an element is removed (e.g. they go back) - that state data also is
  removed.

  We have location & history methods.  Location is the current content location request (which is
  formed as a hash of the request type and the e.g. path if it's a path request).
  Histories are previous requests.
  We're mimicking the History API of browsers intentionally here, as it's familiar.

  .

  Explanation of Value options:
  ----------------------------
  urlParamName:
    Querystring parameter name when updating/reading the Url.

  encodeUrl:
    Obfuscates the Url querystring value.

  requestToShowOnConnect:
    If we're updating Url on show (which is a per-request param) - the produced URL when used
    will immediately re-launch the modal from the Url querystring.  This is how modals can be
    URL-shareable.  Intended for bigger modals as opposed to simple (e.g. video playing) modals.
    The *entire* show request goes into the URL - so this works for *all* types of modals, including
    any css class customisations etc.

  immediatelyShowPath:
    Allows a path to be loaded into a modal on page load.  This is a secondary option to the
    automatic writing/reading of Urls provided by requestToShowOnConnect, but allows e.g. a human-
    readable/writable template link to be added whose URL will launch a modal, without needing to
    add a stimulus show method call.  Normally probably not required.

  immediatelyShowModalCssClasses:
    Allows modal css classes to be specified if immediately loading using immediatelyShowPath.

  showOverlay:
    Allows the overlay to be shown (default true).

  listenForEsc:
    Allows the Esc key listener to not be added - as it's only worthwhile on root element.

  modalCssClassesDefault:
    Allows the default css classes of the modal itself to be set/overridden.

  htmlCssClassWhenHaveModal:
    This is the css class added to the main <html> element when have modal.
  */
  static values = {
    urlParamName: { type: String, default: 'modal' },
    encodeUrl: { type: Boolean, default: true },
    requestToShowOnConnect: String,
    immediatelyShowPath: String,
    immediatelyShowModalCssClasses: String,
    showOverlay: { type: Boolean, default: true },
    listenForEsc: { type: Boolean, default: true },
    autoAddBackLink: { type: Boolean, default: false },
    modalCssClassesDefault: { type: String, default: 'modal is-visible modal-content-loading' },
    htmlCssClassWhenHaveModal: { type: String, default: 'has-modal' },
    contentLoadFailedMessage: { type: String, default: 'Sorry, this content could not be loaded' },
    videoCookiesAllowed: { type: Boolean, default: false },
  };

  connect() {
    if (this.requestToShowOnConnectValue) {
      let request = this.requestToShowOnConnectValue;
      if (this.encodeUrlValue) {
        // eslint-disable-next-line max-len
        const base64Regex = new RegExp(/^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/);
        if (base64Regex.test(request)) {
          request = window.atob(request);
        }
      }
      const requestParams = JSON.parse(request);
      const event = new Event('ImmediateShowEvent');
      event.params = requestParams;
      this.show(event);
    } else if (this.immediatelyShowPathValue) {
      const event = new Event('ImmediateShowEvent');
      const params = { type: 'path', path: this.immediatelyShowPathValue };
      if (this.immediatelyShowModalCssClassesValue) {
        params.modalCssClasses = this.immediatelyShowModalCssClassesValue;
      }
      event.params = params;
      this.show(event);
    }
  }

  async show(event) {
    event.preventDefault();
    event.stopPropagation();

    let request = event.params;
    if (event.type === 'modalShowEvent') {
      request = event.detail.params;
    }
    this.consoleLog('show', request);
    this.recordLauncher(event.target);

    this.prepareOverlay();
    this.prepareModal();
    this.prepareEscKeyListener();
    this.prepareForTouchDevices();

    this.hideLastModalContentTarget();
    this.createNewLastModalContentTarget();
    this.addLocationToLastModalContentTarget(request);
    this.updateModalCssClasses();
    this.createLoadingHTML();

    this.setFocusToCloseButton();

    if (await this.loadModalContent(request) !== '') {
      // Success.
      this.prepareBackLink();
      this.startHeightAnimation();
      this.updateUrl();
    } else {
      this.consoleLog('Could not load content', await this.loadModalContent(request));
    }
  }

  addLocationToLastModalContentTarget(request) {
    this.consoleLog('addLocationToLastContentTarget, request is ', request);
    this.lastModalContentTarget().dataset.location = JSON.stringify(request);
  }

  async back() {
    if (this.histories().length === 0) { return; }

    this.lastModalContentTarget().remove();
    this.showLastModalContentTarget();
    this.updateModalCssClasses();
    this.prepareBackLink();
    this.startHeightAnimation();
    this.updateUrl();
  }

  updateUrl() {
    // Only continue if this feature was requested.
    if (!this.location().updateUrlWhenShow) { return; }

    let modalParam = JSON.stringify(this.location());
    if (this.encodeUrlValue) {
      modalParam = window.btoa(modalParam);
    }

    // Update this querystring param, but not disturb/remove any other qs params, or the hash.
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(this.urlParamNameValue, modalParam);
    const newPath = `${window.location.pathname}?${searchParams.toString()}${window.location.hash}`;
    window.history.replaceState(null, '', newPath);
  }

  clearUrl() {
    // Only continue if this feature was requested.
    if (!this.location().updateUrlWhenShow) { return; }

    // Remove this querystring param, but not disturb/remove any other qs params, or the hash.
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete(this.urlParamNameValue);
    let search = searchParams.toString();
    if (search !== '') { search = `?${search}`; }
    const newPath = `${window.location.pathname}${search}${window.location.hash}`;
    window.history.replaceState(null, '', newPath);
  }

  async reload() {
    this.createLoadingHTML();
    if (await this.loadModalContent(this.location()) !== '') {
      // Success.
      this.startHeightAnimation();
    } else {
      this.consoleLog('Could not reload content', await this.loadModalContent(this.location()));
    }
  }

  async loadModalContent(request) {
    const loader = ModalContentLoaders.loaderForType(request.type);
    this.consoleLog('loader: ', loader);

    if (loader) {
      const load = await loader.load(request, this);
      if (load.ok) {
        this.lastModalContentTarget().innerHTML = load.html;
        return true;
      }
      this.consoleLog('Modal content load not OK: ', load.error);
    }

    this.lastModalContentTarget().innerHTML = `<p>${this.contentLoadFailedMessageValue}</p>`;
    return false;
  }

  recordLauncher(launchingElement) {
    // Record which element had focus to launch, so can try to return focus correctly when close.

    if (this.hasModalTarget) { return; } // Not very first show request, ignore.
    if (!launchingElement) { return; } // No element, may have been launched by URL instead.
    if (launchingElement === window) { return; } // Probably launched by modalShow event, ignore.

    const element = launchingElement;
    element.dataset.modalTarget = 'launcher';
  }

  returnFocusToLauncher() {
    if (this.hasLauncherTarget) {
      this.launcherTarget.focus();
      delete this.launcherTarget.dataset.modalTarget;
    }
  }

  prepareOverlay() {
    // Don't add overlay if the controller opted out of an overlay.
    if (!this.showOverlayValue) { return; }

    // Only create a grey overlay if we don't already have one.
    if (!this.hasOverlayTarget) {
      this.createOverlayHTML();
    }
  }

  prepareModal() {
    if (!this.hasModalTarget) {
      this.consoleLog('No modal present, will create modal now...');
      this.createModalHTML();
    }
  }

  prepareEscKeyListener() {
    // Don't add if the controller asked not to.  Not worth being added unless on body or window.
    if (!this.listenForEscValue) { return; }

    // this.element is the controller element, i.e. the body tag (usually)
    // Need to amend the action carefully in case other Stimulus controllers/code have actions too.
    let currentAction = this.element.dataset.action || '';
    if (currentAction.length > 0) { currentAction += ' '; }
    this.element.dataset.action = `${currentAction}keydown->modal#closeIfEscPressed`;
  }

  prepareBackLink() {
    // Don't add if the controller asked not to.
    if (!this.autoAddBackLinkValue) { return; }

    this.consoleLog('this.histories: ', this.histories());

    if (this.histories().length === 0) {
      // Back link not applicable.  Remove if already present.
      this.consoleLog('back link should be removed', this.hasBackLinkTarget);
      if (this.hasBackLinkTarget) {
        this.backLinkTarget.remove();
      }
    } else if (!this.hasBackLinkTarget) {
      // Add back link if not already present.
      this.consoleLog('adding back link');
      this.createBackLinkHTML();
    }
  }

  setFocusToCloseButton() {
    if (this.histories().length > 0) { return; } // Don't change focus if a subsequent content load.

    this.closeTarget.focus();
  }

  close(event) {
    event.preventDefault();
    event.stopPropagation();
    this.dispatch('close', { detail: {} });
    this.clearUrl();
    this.removeOverlay();
    this.removeModal();
    this.removeEscKeyListener();
    this.resetTouchDevices();
    this.returnFocusToLauncher();
  }

  cookieConsentUpdated(event) {
    this.consoleLog('modal cookieConsentUpdated', event.detail.videoCookiesAllowed);
    this.videoCookiesAllowedValue = event.detail.videoCookiesAllowed;

    // Reload the modal's content, if we have an open modal:
    if (this.hasModalTarget) {
      this.reload();
    }
  }

  lastModalContentTarget() {
    // Notice:  The plural form of targets below.  Singular form returns the *first*, FYI.
    return this.modalContentTargets[this.modalContentTargets.length - 1];
  }

  location() {
    if (!this.lastModalContentTarget()) return undefined;

    return JSON.parse(this.lastModalContentTarget().dataset.location);
  }

  histories() {
    const histories = [];
    for (let i = 0; i < this.modalContentTargets.length - 1; i += 1) {
      histories.push(JSON.parse(this.modalContentTargets[i].dataset.location));
    }
    return histories;
  }

  lastHistory() {
    return this.histories()[this.histories().length - 1];
  }

  updateModalCssClasses() {
    this.consoleLog('updateModalCssClasses: ', this.location().modalCssClasses);
    if (!this.location().modalCssClasses) {
      this.modalTarget.className = this.modalCssClassesDefaultValue;
    } else {
      this.modalTarget.className = this.location().modalCssClasses;
    }
  }

  removeOverlay() {
    this.consoleLog('removeOverlay');

    if (this.hasOverlayTarget) {
      this.overlayTarget.remove();
    }
  }

  removeModal() {
    this.consoleLog('removing modal');

    if (this.hasModalAndOverlayWrapperTarget) {
      this.modalAndOverlayWrapperTarget.remove();
      if (this.htmlCssClassWhenHaveModalValue) {
        const htmlTag = document.body.parentNode;
        htmlTag.classList.remove(this.htmlCssClassWhenHaveModalValue);
      }
    }
  }

  removeEscKeyListener() {
    // Don't remove if the controller asked not to add previously, as won't be present.
    if (!this.listenForEscValue) { return; }

    this.element.dataset.action = this.element.dataset.action.replace(
      'keydown->modal#closeIfEscPressed',
      '',
    );
  }

  closeIfEscPressed(event) {
    const evt = event || window.event;
    let isEscape = false;
    if ('key' in evt) {
      isEscape = (evt.key === 'Escape' || evt.key === 'Esc');
    } else {
      isEscape = (evt.keyCode === 27);
    }
    if (isEscape) {
      this.consoleLog('Esc key pressed');
      this.close(event);
    }
  }

  prepareForTouchDevices() {
    // TODO:  handle video and google map modals (when added) differently.  Context:
    // Currently the scroll blocking works great for enquiry forms... but not for video or Gmap
    // modals because they display in iframes which would also require an event listener adding to
    // block touchmove, but that would affect interactivity.
    // So instead intercept the click event early if we are using a touch device and just open the
    // video / maps URL directly in a new window.
    //
    // NB: if maps and videos are in landscape orientation aspect ratio boxes inside
    // the modal (as we normally do), then it's unlikely scrolling will be necessary
    // for video and gmap modals anyway, unless the user is viewing the site in
    // landscape orientation themselves, in which case vertical scrolling may be required

    this.boundIgnoreBodyTouch = this.ignoreBodyTouch.bind(this);
    this.element.addEventListener(
      'touchmove',
      this.ignoreBodyTouch,
      { passive: false },
    );
  }

  ignoreBodyTouch(event) {
    this.consoleLog('touchmove scroll occured on body, block it');
    event.preventDefault();
  }

  storePositionOfFirstTouch(event) {
    this.previousClientY = event.touches[0].clientY;
    this.consoleLog(
      'modalBodyTarget touchstart occured. previousClientY relative to viewport = ',
      this.previousClientY,
    );
  }

  preventScrollingBeyondModalBox(event) {
    // Allow touchmove on modalBody
    // We blocked scrolling on the entire body. Override that for the modalBody
    event.stopPropagation();

    // Log current Y position, so we can compare it with previous Y position
    const currentClientY = event.touches[0].clientY;

    // If the scroll is at the top of the box, then stop scroll up.
    if (this.modalBodyTarget.scrollTop <= 0 || this.modalBodyTarget.scrollTop === undefined) {
      if (currentClientY > this.previousClientY) {
        this.consoleLog('Scroll is at the top of the box, and you scrolled up. Stop scroll up');
        event.preventDefault();
      }
    }

    // If the scroll is at the end of the box, then stop scroll down.
    // eslint-disable-next-line max-len
    if (this.modalBodyTarget.clientHeight + this.modalBodyTarget.scrollTop === this.modalBodyTarget.scrollHeight) {
      if (this.previousClientY > currentClientY) {
        this.consoleLog(
          'Scroll is at the bottom of the box, and you scrolled down. Stop scroll down',
        );
        event.preventDefault();
      }
    }
  }

  resetTouchDevices() {
    this.element.removeEventListener(
      'touchmove',
      this.ignoreBodyTouch,
      { passive: false },
    );
  }

  handleKeydown(event) {
    if (event.which === 9) {
      this.performTabTrapping(event);
    }
  }

  performTabTrapping(event) {
    const keyCode = event.which;
    const tabKey = (keyCode === 9);
    const { shiftKey } = event;
    const tabbing = (tabKey && !shiftKey);
    const shiftTabbing = (tabKey && shiftKey);

    if (!tabbing && !shiftTabbing) { return; }

    // Find all tab-selectable elements, looking only at elements within this.element.
    const tabSelectableElements = this.modalTarget.querySelectorAll(
      'a:not([tabindex="-1"]), button:not([disabled]), input, [tabindex="0"]',
    );
    // this.consoleLog('tabSelectableElements', tabSelectableElements);
    // this.consoleLog('document.activeElement', document.activeElement);
    const index = Array.prototype.indexOf.call(tabSelectableElements, document.activeElement);
    const number = index + 1;
    const nextNumber = number + 1;
    const previousNumber = number - 1;

    if (tabbing && (nextNumber > tabSelectableElements.length)) {
      this.consoleLog(
        'Trying to tab and currently at last tab-selectable element.  Cycling them to the first.',
        tabSelectableElements[0],
      );
      event.preventDefault();
      tabSelectableElements[0].focus();
    }

    if (shiftTabbing && (previousNumber < 1)) {
      this.consoleLog(
        'Trying to shift+tab to before the first tab-selectable element.  Will cycle them to last.',
        tabSelectableElements[tabSelectableElements.length - 1],
      );
      event.preventDefault();
      tabSelectableElements[tabSelectableElements.length - 1].focus();
    }
  }

  /* eslint-disable-next-line class-methods-use-this, no-unused-vars */
  consoleLog(...args) {
    // eslint-disable-next-line no-console
    // console.log('modal:   ', ...args); // Uncomment this line to assist debugging/development.
  }

  // ===============================================================================================
  //          Methods with HTML/CSS within are below ...
  // ===============================================================================================

  hideLastModalContentTarget() {
    // Make current content holder be display: none, if present.
    if (this.lastModalContentTarget()) {
      this.lastModalContentTarget().style = 'display: none;';
    }
  }

  showLastModalContentTarget() {
    this.lastModalContentTarget().style = 'display: auto;';
  }

  createNewLastModalContentTarget() {
    // Note:  The request (location) hash will later be added to this, so it self-describes in DOM.
    const newContent = document.createRange().createContextualFragment(`
      <div class="modal__content" data-modal-target="modalContent"></div>
    `);
    this.modalBodyTarget.appendChild(newContent);
  }

  createOverlayHTML() {
    // This HTML could be pre-rendered, but for sake of content which doesn't need modal, injecting.
    this.consoleLog('creating overlay');

    const overlay = document.createRange().createContextualFragment(`
      <div
        class="modal__wrapper"
        data-modal-target="modalAndOverlayWrapper"
      >
        <div
          class="overlay is-visible"
          data-modal-target="overlay"
          data-action="click->modal#close">
        </div>
      </div>
    `);

    this.element.append(overlay);
  }

  createModalHTML() {
    // This HTML could be pre-rendered, but for sake of content which doesn't need modal, injecting.
    this.consoleLog('creating modal HTML');

    const modal = document.createRange().createContextualFragment(`
      <div
        class="${this.modalCssClassesDefaultValue}"
        data-modal-target="modal"
        data-action="keydown@window->modal#handleKeydown"
      >
        <div
          class="modal__body"
          data-modal-target="modalBody"
          data-action="
            touchstart->modal#storePositionOfFirstTouch:!passive
            touchmove->modal#preventScrollingBeyondModalBox:!passive
          "
        >

          <header class="modal__header" data-modal-target="modalHeader">
            <a
              href="#"
              class="modal__close"
              data-action="click->modal#close"
              data-modal-target="close"
            >
              <svg class="close__icon" focusable="false" aria-hidden="true">
                <use
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xlink:href="#svg--close"
                  aria-hidden="true"
                ></use>
              </svg>
            </a>
          </header>

        </div>
      </div>
    `);

    this.modalAndOverlayWrapperTarget.append(modal);

    // Now add class to <html> tag so can prevent body scrolling while have modal.
    const htmlTag = document.body.parentNode;
    if (this.htmlCssClassWhenHaveModalValue) {
      htmlTag.classList.add(this.htmlCssClassWhenHaveModalValue);
    }
  }

  createLoadingHTML() {
    if (this.lastModalContentTarget()) {
      this.lastModalContentTarget().innerHTML = '<div class="loader-pulsing"></div>';
    }
  }

  startHeightAnimation() {
    this.modalTarget.classList.remove('modal-content-loading');
    this.modalTarget.classList.add('modal-content-loaded');
  }

  createBackLinkHTML() {
    const backLink = document.createRange().createContextualFragment(`
      <button
        class="modal__back-link btn btn--brand"
        data-modal-target="backLink"
        data-action="click->modal#back"
      >
        <svg focusable="false" aria-hidden="true">
          <use
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xlink:href="#svg--arrow"
            aria-hidden="true">
          </use>
        </svg>
        <span>Back</span>
      </button>
    `);

    this.modalHeaderTarget.prepend(backLink);
  }
}
