import { Controller } from '@hotwired/stimulus';

// This pushes events to dataLayer for DotDigital (if consented).

// Connects to data-controller="dotdigital-event"
export default class extends Controller {
  static values = {
    parameters: Object,
  };

  connect() {
    window.dmPt('identify', this.parametersValue);
  }
}
