import Glide from '@glidejs/glide';

class ReviewsSlider {
  constructor({ selector }) {
    this.selector = document.querySelector(selector);

    if (!this.selector) { return null; }

    this.slides = this.selector.querySelectorAll('.glide__slide');

    // Define vars
    this.vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    this.reviewsSliderBreakpoint = 1080; // px value to re-init the carousel if needed
    this.viewportWidthThreshold = '';

    // Set default slider options
    this.slidePauseTime = 4500;
    this.mobileAutoplay = this.slidePauseTime;
    this.mobileFocusAt = 'center';
    this.mobileLargeFocusAt = 'center';
    this.tabletFocusAt = 'center';
    this.tabletAutoplay = this.slidePauseTime;
    this.desktopFocusAt = 'center';
    this.desktopPerView = 4;
    this.desktopAutoplay = this.slidePauseTime;

    // 1 review slider
    if (this.slides.length === 1) {
      this.selector.classList.add('slide-total-1');
    }

    // 2 reviews slider options overrides
    if (this.slides.length === 2) {
      this.selector.classList.add('slide-total-2');
      // Set options
      this.mobileLargeFocusAt = 2;
      this.tabletAutoplay = false;
      this.tabletFocusAt = 2;
      this.desktopAutoplay = false;
      this.desktopPerView = 2;
      this.desktopFocusAt = 2;
    }

    // 3 reviews slider option overrides
    if (this.slides.length === 3) {
      this.selector.classList.add('slide-total-3');
      // Set options
      this.tabletFocusAt = 'center';
      this.desktopAutoplay = false;
      this.desktopPerView = 3;
    }

    // Create slider options
    this.sliderOptions = {
      type: 'carousel',
      gap: 30,
      animationDuration: 500,
      perView: this.desktopPerView,
      autoplay: this.desktopAutoplay,
      focusAt: this.desktopFocusAt,
      autoheight: true,
      hoverpause: false,
      swipeThreshold: false,
      dragThreshold: false,
      breakpoints: {
        1079: {
          perView: 2,
          autoplay: this.tabletAutoplay,
          focusAt: this.tabletFocusAt,
        },
        767: {
          gap: 20,
          perView: 2,
          focusAt: this.mobileLargeFocusAt,
        },
        600: {
          perView: 1,
          autoplay: this.mobileAutoplay,
          focusAt: this.mobileFocusAt,
        },
      },
    };

    // Mount if more than one slide
    if (this.slides.length > 1) {
      this.mountSlider(this.selector, this.slides);
    } else {
      // There is one slide or less
      // Add active class to the only slide so the caption animation still works
      const onlyChildSlide = this.selector.querySelector('.glide__slide');
      const arrowNav = this.selector.querySelector('[data-glide-el="controls"]');

      if (arrowNav) {
        arrowNav.style.display = 'none';
      }

      // Add active class after delay, so can animate it in still
      setTimeout(() => {
        onlyChildSlide.classList.add('glide__slide--active');
      }, 300);
    }

    this.viewportWidthChecker();
    this.resizeWindowEvents();
  }

  mountSlider(selector, slides) {
    if (!this.selector) { return; }

    let nextLastSlide;
    const lastSlideIndex = slides.length - 1;

    this.reviewsSlider = new Glide(selector, this.sliderOptions);

    this.reviewsSlider.on('move', () => {
      if (this.reviewsSlider.index > 0) {
        // Any slide other than the first

        // Add class to start opacity/fade animation
        slides[this.reviewsSlider.index].classList.add('about-to-slide');

        // Select the previous slide and remove class for animation
        const previousSlideIndex = this.reviewsSlider.index - 1;
        slides[previousSlideIndex].classList.remove('about-to-slide');

        // Select clone of first slide
        nextLastSlide = selector.querySelector('.glide__slide:not(.glide__slide--clone) + .glide__slide--clone');

        if (nextLastSlide) {
          // Make sure class is removed off first slide clone
          nextLastSlide.classList.remove('about-to-slide');
        }

        // On the last slide
        if (this.reviewsSlider.index === lastSlideIndex) {
          slides[previousSlideIndex].classList.remove('about-to-slide');
        }
      } else {
        // On the first slide
        slides[this.reviewsSlider.index].classList.add('about-to-slide');

        // Won't exist first time around
        if (nextLastSlide) {
          // Add class to first slide clone
          // It is shown until the animtion back to the first slide is complete
          nextLastSlide.classList.add('about-to-slide');
        }
        // Target the last slide and remove the class
        slides[lastSlideIndex].classList.remove('about-to-slide');
      }
    });

    this.reviewsSlider.mount();
  }

  // Check width of the viewport
  viewportWidthChecker() {
    // if viewport is greater than the navigationBreakpoint
    if (this.vw >= this.reviewsSliderBreakpoint) {
      if (this.viewportWidthThreshold !== 'higher') {
        this.viewportWidthThreshold = 'higher';
        this.carouselToggleSwitch();
      }
    } else if (this.viewportWidthThreshold !== 'lower') {
      this.viewportWidthThreshold = 'lower';
      this.carouselToggleSwitch();
    }
  }

  // Switch to decide whether to pause or play
  carouselToggleSwitch() {
    if (this.viewportWidthThreshold === 'higher') {
      // console.log("above");
      // Pause slider
      // Don't actually pause, discovered this can sometimes leave a slide off page
      // this.reviewsSlider.pause();
    } else if (this.slides.length > 1) {
      // console.log("below vw threshold and more than 1 slide");
      this.reviewsSlider.pause(); // need to pause first
      this.reviewsSlider.play();
    }
  }

  resizeWindowEvents() {
    // if viewport is resized check whether we need to pause or play the slider
    window.addEventListener('resize', () => {
      this.vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
      // constantly run the VW checker as we resize the window
      this.viewportWidthChecker();
    }, { passive: true });
    // Set event listener to passive as we're not changing the default event behaviour of resize
  }
}

export default ReviewsSlider;
