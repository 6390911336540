import Countdown from './countdown';

class Countdowns {
  constructor() {
    const dates = document.querySelectorAll('[data-countdown]');
    for (let i = 0; i <= dates.length - 1; i += 1) {
      const element = dates[i];
      const endDate = element.getAttribute('data-countdown');
      if (element) {
        new Countdown({ endDate, element }).startCountdown();
      }
    }
  }
}

export default Countdowns;
