import Axios from 'axios';

class CompaniesHouseApi {
  constructor() {
    this.input = document.querySelector('[data-company-reg-input]');

    if (!this.input) return;

    this.fetch = document.querySelector('[data-company-reg-fetch]');
    this.errorBox = document.querySelector('[data-lookup-fail]');
    this.fetch.addEventListener('click', this.getCompanyDataByRef.bind(this));
  }

  getCompanyDataByRef(e) {
    e.preventDefault();

    return Axios.get(`/companies_house_lookups/${this.input.value}.json`,
      { headers: { 'Content-Type': 'application/json' } }).then((response) => {
      if ('error' in response.data) {
        this.errorBox.classList.remove('visually-hidden');
      } else {
        this.errorBox.classList.add('visually-hidden');
        this.setName(response.data);
        this.setAddress(response.data);
      }
    });
  }

  setAddress(data) {
    const address1 = document.querySelector('[data-address-1]');
    const address2 = document.querySelector('[data-address-2]');
    const address3 = document.querySelector('[data-address-3]');
    const postcode = document.querySelector('[data-postcode]');

    address1.value = data.address1;
    address2.value = data.address2;
    address3.value = data.address3;
    postcode.value = data.postcode;

    return this;
  }

  setName(data) {
    const name = document.querySelector('[data-company-name]');
    name.value = data.company_name;

    return this;
  }
}

export default CompaniesHouseApi;
