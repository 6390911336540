class FromScrape {
  /* eslint class-methods-use-this: "off" */

  async load(request) {
    if (!request.scrapeSelector) {
      return { ok: false, error: 'Scrape selector not passed' };
    }

    const element = document.querySelector(request.scrapeSelector);

    if (element) {
      const html = element.innerHTML;
      return { ok: true, html };
    }

    return { ok: false, error: 'Scrape element not found' };
  }
}

export default FromScrape;
