import { Controller } from '@hotwired/stimulus';

/* eslint class-methods-use-this: "off" */

// Connects to data-controller="google-id"
export default class extends Controller {
  connect() {
    this.prefill();
  }

  prefill() {
    if ((window.gtag === undefined) || (window.googleIdPrefill === undefined)) {
      setTimeout(() => {
        this.prefill();
      }, 1000);
      return;
    }
    window.gtag('get', 'G-6FN96M6NZK', 'client_id', (clientId) => {
      window.googleClientId = clientId;
      window.googleIdPrefill();
    });
  }
}
