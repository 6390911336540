import { Controller } from '@hotwired/stimulus';
import debounce from 'lodash/debounce';
import { MarkerClusterer, SuperClusterAlgorithm } from '@googlemaps/markerclusterer';
import CustomRenderer from '../javascripts/application/marker-clusterer/custom-renderer';

/* eslint class-methods-use-this: "off" */
/* eslint no-underscore-dangle: "off" */
/* eslint max-len: ["error", { "code": 120 }] */

// ---------------------------------  Summary of approach:  ----------------------------------------
// This controller could have extended the generic MapController (which is what the standard map
// contents module now uses).  But that made this solution heavier/scarier to deal with, and saved
// little more than a few basic method calls.  So - as long as both share the same one google maps
// script (as only one invocation is permitted per page by Google), responding to the same callback
// event arrangement - it's simpler/better to just have each as separate controller.
//
// The search form (rails_ujs) is submitted (via Stimulus), which posts to a Rails controller,
// which will then update the DOM - to update the data-map-locations-value, then uses
// https://stimulus.hotwired.dev/reference/values#change-callbacks to listen for the value change,
// and redraws the markers.  Also re-renders the tiles of updated businesses/locations.
// The form can't submit immediately - we need to wait for Google Maps Places response - and then
// put into hidden fields (targets) for latitude and longitude - and then trigger the submit - so
// Rails has the coordinates for the locations search.  This avoids needing to also have a
// Rails-based Google Maps gem/call, as we're already talking to their API via javascript.
// Less things to go wrong this way too - otherwise the submit might be broken due to a gem issue,
// but we couldn't tell so readily.

// Connects to data-controller="self-storage-map"
export default class extends Controller {
  static targets = [
    'wrapperForWelcome',
    'wrapperForResults',
    'mapHolder',
    'businessCards',
    'form',
    'search',
    'latitude',
    'longitude',
    'zoom',
    'loadingIndicator',
    'searchErrorMessage',
    'geolocationErrorMessage',
  ];

  static values = {
    latitude: Number, // Naturally has a default of 0
    longitude: Number, // Naturally has a default of 0
    zoom: Number, // Naturally has a default of 0
    defaultLatitude: Number, // Passed in for latitude anyway, but required for reset and url
    defaultLongitude: Number, // Passed in for longitude anyway, but required for reset and url
    defaultZoom: Number, // Passed in for zoom anyway, but required for reset and url
    locations: Array, // Data of locations to be shown as markers.
    cluster: { type: Boolean, default: false },
    clusterRadius: { type: Number, default: 40 },
    infoWindows: { type: Boolean, default: true }, // Whether to show infowindows for markers.
    markerIconUrl: String,
    premiumMarkerIconUrl: String,
    autoMoveMapEnabled: { type: Boolean, default: true }, // Track when safe to automatically move.
    drawMapRadiusCircle: { type: Boolean, default: false }, // Use only for checking radius/zoom.
    lastServerRenderAt: Number, // Allows us to know when response completed even if results unchanged.
  }

  initialize() {
    this.markers = [];
  }

  connect() {
    // This normally won't be required - as the google maps script tag uses `async defer`, and we
    // use a callback event, but if that changes to be a blocking load before this runs, this will
    // still work fine in that html/dom scenario.
    if (typeof (google) !== 'undefined') {
      this.initMap();
    }
  }

  initMap() {
    const { google } = window;

    this.createMap();
    this.infoWindow = new google.maps.InfoWindow();
    this.addMarkers();
    if (this.coordsAndZoomAllDefault()) {
      // Fresh querystring-less load.  We're not preloading specific coords & zoom.
      this.autoMoveMap();
      this.setWelcomeVisible();
    } else {
      // Reloading previous coordinates and zoom
      this.placeMarker().setPosition({ lat: this.latitudeValue, lng: this.longitudeValue });
      this.placeMarker().setVisible(true);
      if (this.drawMapRadiusCircleValue) {
        this.drawMapRadiusCircle(this.map.getCenter());
      }
      this.setResultsVisible();
    }
    this.autocomplete();
    setTimeout(() => {
      // Required to give markers a chance to be added and map moved/zoomed first, to avoid
      // triggering a form submit of identical data.
      this.addMapListeners();
    }, 100);
  }

  mapOptions() {
    const { google } = window;

    return {
      center: { lat: this.latitudeValue, lng: this.longitudeValue },
      zoom: this.zoomValue,
      minZoom: 6,
      maxZoom: 16,
      mapTypeId: 'roadmap',
      mapTypeControl: true,
      scaleControl: true,
      streetViewControl: true,
      rotateControl: true,
      fullscreenControl: true,
      zoomControl: true,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
        mapTypeIds: ['roadmap', 'satellite', 'hybrid'],
        position: google.maps.ControlPosition.RIGHT_TOP,
      },
      styles: [
        { elementType: 'geometry', stylers: [{ saturation: -100 }] },
        { elementType: 'labels.text.stroke', stylers: [{ saturation: -100 }] },
        { elementType: 'labels.text.fill', stylers: [{ saturation: -100 }] },
      ],
    };
  }

  markerIcon(premium, large = false) {
    const { google } = window;

    const normalSize = new google.maps.Size(25, 37.5);
    const largeSize = new google.maps.Size(37.5, 56.25);

    return {
      url: (premium ? this.premiumMarkerIconUrlValue : this.markerIconUrlValue),
      size: (large ? largeSize : normalSize),
      scaledSize: (large ? largeSize : normalSize),
    };
  }

  createMap() {
    this.consoleLog('createMap');
    const { google } = window;

    this.map = new google.maps.Map(
      this.mapHolderTarget,
      this.mapOptions(),
    );

    this.bounds = new google.maps.LatLngBounds();

    return this.map;
  }

  addMapListeners() {
    this.consoleLog('addMapListeners');

    const mapDraggedDebouncer = debounce(this.mapDragged.bind(this), 750);
    this.mapDraggedListener = this.map.addListener('dragend', mapDraggedDebouncer);

    const mapZoomedDebouncer = debounce(this.mapZoomed.bind(this), 1000);
    this.mapZoomedListener = this.map.addListener('zoom_changed', mapZoomedDebouncer);
  }

  removeMapListeners() {
    this.consoleLog('removeMapListeners');
    this.mapDraggedListener.remove();
    this.mapZoomedListener.remove();
  }

  mapDragged() {
    const center = this.map.getCenter();
    if (center.lat() === this.latitudeValue && center.lng() === this.longitudeValue) {
      // Probably on mobile - where drag event fired - but user only saw 'use two fingers' to move.
      return;
    }

    this.consoleLog('mapDragged');
    this.mapDraggedOrZoomed(center);
  }

  mapZoomed() {
    const center = this.map.getCenter();
    this.consoleLog('mapZoomed');
    this.mapDraggedOrZoomed(center);
  }

  mapDraggedOrZoomed(center) {
    this.autoMoveMapEnabledValue = false;
    this.showLoadingIndicator();
    this.setCoordinatesAndSubmit(center.lat(), center.lng(), false);
  }

  addMarkers() {
    this.consoleLog('addMarkers()');
    const { google } = window;

    this.locationsValue.forEach((location) => {
      const latLng = {
        lat: parseFloat(location.latitude),
        lng: parseFloat(location.longitude),
      };

      const markerIcon = this.markerIcon(location.businessPremium);

      const marker = new google.maps.Marker({
        position: latLng,
        map: this.map,
        icon: markerIcon,
      });

      if (this.infoWindowsValue) {
        this.addMarkerInfoWindowListener(location, marker);
      }

      this.markers.push(marker);

      this.bounds.extend(latLng);
    });

    if (this.clusterValue) {
      if (this.markerClusterer !== undefined) {
        this.markerClusterer.clearMarkers();
      }
      this.markerClusterer = new MarkerClusterer({
        map: this.map,
        markers: this.markers,
        algorithm: new SuperClusterAlgorithm({ radius: this.clusterRadiusValue }),
        renderer: new CustomRenderer(),
      });
    }
  }

  autoMoveMap() {
    this.consoleLog('this.autoMoveMapEnabledValue:', this.autoMoveMapEnabledValue);

    if (this.autoMoveMapEnabledValue && this.locationsValue.length > 0) {
      // They probably used place lookup or geolocate.  Move and zoom nicely for them.
      this.consoleLog('Nicely arrange the map centre and zoom level.');
      this.map.fitBounds(this.bounds);
    } else {
      this.consoleLog('Not to automove the map, and/or no locations found.');
    }

    if (this.drawMapRadiusCircleValue) {
      this.drawMapRadiusCircle(this.map.getCenter());
    }
  }

  addMarkerInfoWindowListener(location, marker) {
    marker.addListener('click', () => {
      this.infoWindow.setContent(this.infoWindowContent(location));
      this.infoWindow.open(this.map, marker);
    });
  }

  infoWindowContent(location) {
    // Note:  We have to construct our HTML here, as opposed to passing in a rendered partial (as
    // the standard MapController does) - as when rails_ujs tries to re-render the locations value
    // once the form has been submitted - it can't handle the sequence of encoding changes
    // successfully (js.erb response, with html string property in a json object).  Turbo would fare
    // better, but it's not currently present in this site.

    let content = `
      <div class="map__infowindow">
        <p>${location.businessName}</p>
        <p>${location.fullAddress}</p>
    `;
    if (location.distance !== null) {
      content += `
        <p>${Math.round(location.distance * 10) / 10} miles away</p>
      `;
    }
    content += `
      </div>
    `;
    return content;
  }

  indicateBusinessLocations(event) {
    const { google } = window;
    const { businessId } = event.params;

    this.locationsValue.forEach((location, index) => {
      if (location.businessId === businessId) {
        const marker = this.markers[index];
        marker.setAnimation(google.maps.Animation.BOUNCE);
        marker.setIcon(this.markerIcon(location.businessPremium, true));
      }
    });
  }

  unIndicateBusinessLocations(event) {
    const { businessId } = event.params;
    this.consoleLog(event, businessId);

    this.locationsValue.forEach((location, index) => {
      if (location.businessId === businessId) {
        const marker = this.markers[index];
        marker.setAnimation(null);
        marker.setIcon(this.markerIcon(location.businessPremium, false));
      }
    });
  }

  removeMarkers() {
    if (this.markers === undefined) { return; }

    // this.consoleLog('Preparing to remove markers.  Currently they are:', this.markers);
    this.markers.forEach((marker) => {
      marker.setMap(null);
      const m = marker;
      m.visible = false;
    });
    this.markers = [];
    // this.consoleLog('Removed markers...  They are now: ', this.markers);
  }

  lastServerRenderAtValueChanged() {
    // Stimulus value change event (in DOM - updated by rails_ujs search results)
    this.consoleLog('lastServerRenderAtValueChanged');

    // This method will be called before the map has been created, so need to handle that:
    if (this.map === undefined) { return; }

    this.hideLoadingIndicator();
    this.clearErrorMessages();
    setTimeout(() => {
      // Not strictly necessary, as locationsValueChanged always seems to run first, but in case
      // that ever changes - this should mitigate against autoMoveMap triggering zoom/drag listener
      // again, into an infinite loop.
      this.addMapListeners();
    }, 100);
  }

  /* eslint no-unused-vars: "off" */
  locationsValueChanged(_value, _previousValue) {
    // Stimulus change event for locations value (in DOM - updated by rails_ujs search results)
    this.consoleLog('locationsValueChanged');

    // This method will be called before the map has been created, so need to handle that:
    if (this.map === undefined) { return; }

    if (this.coordsAndZoomAllDefault()) {
      this.setWelcomeVisible();
    } else {
      this.setResultsVisible();
    }

    this.removeMarkers();
    this.addMarkers();
    this.autoMoveMap();
    this.businessCardsTarget.scroll({ top: 0, behavior: 'smooth' });
  }

  setWelcomeVisible() {
    this.wrapperForWelcomeTarget.classList.remove('hidden');
    this.wrapperForWelcomeTarget.classList.add('visible');
    this.wrapperForResultsTarget.classList.remove('visible');
    this.wrapperForResultsTarget.classList.add('hidden');
  }

  setResultsVisible() {
    this.wrapperForWelcomeTarget.classList.remove('visible');
    this.wrapperForWelcomeTarget.classList.add('hidden');
    this.wrapperForResultsTarget.classList.remove('hidden');
    this.wrapperForResultsTarget.classList.add('visible');
  }

  autocomplete() {
    const { google } = window;

    if (this._autocomplete === undefined) {
      const northWestCorner = new google.maps.LatLng(59.6, -9.6);
      const southEastCorner = new google.maps.LatLng(49.8, 1.6);
      const uKBounds = new google.maps.LatLngBounds(northWestCorner, southEastCorner);

      this._autocomplete = new google.maps.places.Autocomplete(
        this.searchTarget,
        {
          // Restrict Places API to UK-only - so don't see irrelevant suggestions:
          componentRestrictions: { country: 'UK' },
          bounds: uKBounds,
          // Restrict Places API to SKU Basic Data to avoid unnecessary API costs:
          fields: ['address_component', 'geometry', 'icon', 'name'],
        },
      );

      this._autocomplete.addListener('place_changed', this.placeChanged.bind(this));
    }

    return this._autocomplete;
  }

  preventSubmit(event) {
    this.hideLoadingIndicator();
    this.clearErrorMessages();
    if (event.key === 'Enter') { event.preventDefault(); }
  }

  placeChanged() {
    this.autoMoveMapEnabledValue = true;

    const place = this.autocomplete().getPlace();
    this.consoleLog('place is: ', place);

    if (!place.geometry) {
      // User entered the name of a Place that was not suggested and pressed the Enter key,
      // or the Place Details request failed.
      this.searchErrorMessage(`
        <div class="message-box message-box--error">
          <span class="message-text message-text--error">
            No details available for '${place.name}'.
            Please review or rephrase your search until you can select a matched place from the list.
          </span>
        </div>
      `);
      return;
    }

    const { location } = place.geometry;

    if ((this.latitudeValue === location.lat().toString())
      && (this.longitudeValue === location.lng().toString())) {
      // No change in coordinates, so just return.  Plus need to hide the loading indicator and any error.
      this.hideLoadingIndicator();
      this.searchErrorMessage('');
      return;
    }

    this.showLoadingIndicator();
    this.setCoordinatesAndSubmit(location.lat(), location.lng());
  }

  showLoadingIndicator() {
    this.loadingIndicatorTarget.style.display = 'block';
  }

  hideLoadingIndicator() {
    this.loadingIndicatorTarget.style.display = 'none';
  }

  searchErrorMessage(message) {
    this.searchErrorMessageTargets.forEach((target) => {
      const t = target;
      t.innerHTML = message;
    });
  }

  geolocationErrorMessage(message) {
    this.geolocationErrorMessageTargets.forEach((target) => {
      const t = target;
      t.innerHTML = message;
    });
  }

  clearErrorMessages() {
    this.searchErrorMessage('');
    this.geolocationErrorMessage('');
  }

  reset(event) {
    event.preventDefault();

    this.removeMapListeners();
    this.autoMoveMapEnabledValue = false;

    this.showLoadingIndicator();

    this.map.setZoom(this.defaultZoomValue);
    this.map.panTo({ lat: this.defaultLatitudeValue, lng: this.defaultLongitudeValue });

    this.latitudeValue = this.defaultLatitudeValue;
    this.longitudeValue = this.defaultLongitudeValue;
    this.zoomValue = this.defaultZoomValue;

    this.placeMarker().setPosition(null);
    this.placeMarker().setVisible(false);
    this.searchTarget.value = '';

    this.pushIntoUrl();
    this.submitSearchForm();

    this.autoMoveMapEnabledValue = true;
  }

  setCoordinatesAndSubmit(latitude, longitude, setPlaceMarker = true) {
    this.consoleLog('setCoordinatesAndSubmit', setPlaceMarker);

    this.latitudeValue = latitude;
    this.longitudeValue = longitude;
    this.zoomValue = this.map.getZoom();

    this.removeMapListeners();

    const location = { lat: latitude, lng: longitude };
    this.map.panTo(location);
    this.bounds = new window.google.maps.LatLngBounds();
    this.bounds.extend(location);

    if (setPlaceMarker) {
      this.placeMarker().setPosition(location);
      this.placeMarker().setVisible(true);
    }

    this.pushIntoUrl();
    this.submitSearchForm();
  }

  pushIntoUrl() {
    // Update this querystring param, but not disturb/remove any other qs params, or the hash.
    const searchParams = new URLSearchParams(window.location.search);
    if (this.coordsAndZoomAllDefault()) {
      searchParams.delete('latitude');
      searchParams.delete('longitude');
      searchParams.delete('zoom');
    } else {
      searchParams.set('latitude', this.latitudeValue);
      searchParams.set('longitude', this.longitudeValue);
      searchParams.set('zoom', this.zoomValue);
    }
    let search = searchParams.toString();
    if (search.length > 1) { search = `?${search}`; }

    const newPath = `${window.location.pathname}${search}${window.location.hash}`;
    window.history.pushState(null, '', newPath);
  }

  coordsAndZoomAllDefault() {
    return (
      this.latitudeValue === this.defaultLatitudeValue
      && this.longitudeValue === this.defaultLongitudeValue
      && this.zoomValue === this.defaultZoomValue);
  }

  submitSearchForm() {
    // This, and the reset, are the only place that should be setting/using these form values, as
    // these are only to be a mechanism for submitting a search.
    // For everything else - use the Stimulus Values.
    this.latitudeTarget.value = this.latitudeValue;
    this.longitudeTarget.value = this.longitudeValue;
    this.zoomTarget.value = this.zoomValue;
    this.formTarget.requestSubmit();
  }

  placeMarker() {
    const { google } = window;

    if (this._placeMarker === undefined) {
      this._placeMarker = new google.maps.Marker({
        map: this.map,
        anchorPoint: new google.maps.Point(0, 0),
      });
      const mapLocation = {
        lat: parseFloat(this.latitudeValue),
        lng: parseFloat(this.longitudeValue),
      };
      this._placeMarker.setPosition(mapLocation);
      this._placeMarker.setVisible(true);
    }
    return this._placeMarker;
  }

  geolocate(event) {
    event.preventDefault();
    this.searchTarget.value = '';
    this.searchErrorMessage('');
    this.showLoadingIndicator();
    this.autoMoveMapEnabledValue = true;

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.geolocationSuccess.bind(this),
        this.geolocationError.bind(this),
        {
          timeout: 10000,
          maximumAge: 10000,
        },
      );
    } else {
      this.geolocationErrorMessage(`
        <div class="message-box message-box--error">
          <span class="message-text message-text--error">
            Geolocation is not supported by this browser.
          </span>
        </div>
      `);
    }
  }

  geolocationSuccess(geolocationPosition) {
    this.consoleLog('geolocationSuccess', geolocationPosition);

    const { coords } = geolocationPosition;

    this.consoleLog('geolocationSuccess.', this.latitudeValue, coords.latitude);
    if ((this.latitudeValue === coords.latitude)
      && (this.longitudeValue === coords.longitude)) {
      // No change in location, so just return.  Plus need to hide the loading indicator.
      this.hideLoadingIndicator();
      this.geolocationErrorMessage('');
      return;
    }

    this.setCoordinatesAndSubmit(coords.latitude, coords.longitude);
  }

  geolocationError(geolocationPositionError) {
    this.consoleLog('geolocationError', geolocationPositionError);

    this.hideLoadingIndicator();

    const errorCodes = Object.getPrototypeOf(geolocationPositionError);
    if (geolocationPositionError.code === errorCodes.PERMISSION_DENIED) {
      this.geolocationErrorMessage(`
        <div class="message-box message-box--error">
          <span class="message-text message-text--error">
            Permission was denied.  Please grant permission in your browser then try again.
          </span>
        </div>
      `);
    } else if ((geolocationPositionError.code === errorCodes.POSITION_UNAVAILABLE)
                || (geolocationPositionError.code === errorCodes.TIMEOUT)) {
      this.geolocationErrorMessage(`
        <div class="message-box message-box--error">
          <span class="message-text message-text--error">
            Could not find your location, please try again.
          </span>
        </div>
      `);
    } else {
      this.geolocationErrorMessage(geolocationPositionError.message);
    }
  }

  drawMapRadiusCircle(center) {
    // This is only here as an easy/visual way to check and debug zoom and radius issues.
    const { google } = window;

    const radiusMiles = this.radiusMilesForZoom();
    const radius = radiusMiles * 1609.34;

    if (this.circle !== undefined) {
      this.circle.setMap(null);
    }
    this.circle = new google.maps.Circle({
      center,
      radius,
      fillOpacity: 0.35,
      fillColor: '#007700',
      map: this.map,
    });
  }

  radiusMilesForZoom() {
    const zoom = this.map.getZoom();
    let result;
    switch (zoom) {
      case 6: result = 24; break;
      case 7: result = 23; break;
      case 8: result = 22; break;
      case 9: result = 21; break;
      case 10: result = 20; break;
      case 11: result = 10; break;
      case 12: result = 5; break;
      case 13: result = 2.5; break;
      case 14: result = 1.25; break;
      case 15: result = 0.625; break;
      case 16: result = 0.3125; break;
      default: break;
    }
    return result;
  }

  /* eslint-disable-next-line class-methods-use-this, no-unused-vars */
  consoleLog(...args) {
    // eslint-disable-next-line no-console
    // console.log('SelfStorageMapController:   ', ...args); // Uncomment this line to assist debugging/development.
  }
}
