import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

// Connects to data-controller="direct-upload"
export default class extends Controller {
  static values = { siteKey: String }

  /* eslint class-methods-use-this: "off" */

  connect() {
    this.submitFormOnceAllUploaded();
    this.summateFilesOnChange();
  }

  submitFormOnceAllUploaded() {
    window.addEventListener('direct-uploads:end', () => {
      // All our uploading work is now done (none if no files).
      // Shortly we'll submit the form, but first we need to clear our file input, to avoid the
      // files being submitted again and duplicating. Alternatively we could probably set hidden
      // hidden html inputs of signed_blob_ids, by importing and using @rails/activestorage, but
      // this is easier and seems to have no drawbacks, so take the simple life.
      const input = document.querySelector('input[type=file]');
      input.value = null;

      // Now actually submit, but using Turbo, as opposed to simple .submit()
      const { form } = input;
      Turbo.navigator.submitForm(form);
    });
  }

  showProgressBar(event) {
    // This event listener needs to via Stimulus - to automatically manage/remove duplicates e.g.
    // when form reloads due to failed validation.
    const imagesSelectedPreUpload = document.querySelector('[data-images-selected-pre-upload]');
    imagesSelectedPreUpload.innerHTML = '';

    const { target, detail } = event;
    const { id, file } = detail;
    target.insertAdjacentHTML('beforebegin', `
      <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
        <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
        <span class="direct-upload__filename"></span>
      </div>
    `);
    target.previousElementSibling.querySelector('.direct-upload__filename').textContent = file.name;
  }

  showStarting(event) {
    const { id } = event.detail;
    const element = document.getElementById(`direct-upload-${id}`);
    element.classList.remove('direct-upload--pending');
  }

  updateProgress(event) {
    const { id, progress } = event.detail;
    const progressElement = document.getElementById(`direct-upload-progress-${id}`);
    progressElement.style.width = `${progress}%`;
  }

  showError(event) {
    event.preventDefault();
    const { id, error } = event.detail;
    const element = document.getElementById(`direct-upload-${id}`);
    element.classList.add('direct-upload--error');
    element.setAttribute('title', error);
  }

  showUploadComplete(event) {
    const { id } = event.detail;
    const element = document.getElementById(`direct-upload-${id}`);
    element.classList.add('direct-upload--complete');
  }

  summateFilesOnChange() {
    const status = document.querySelector('[data-images-status]');
    const imagesSelectedPreUpload = document.querySelector('[data-images-selected-pre-upload]');
    document.querySelector('input.file-upload').addEventListener('change', (e) => {
      const fileCount = e.target.files.length;
      const filePlural = (fileCount > 1) ? 's' : '';
      status.innerHTML = `${e.target.files.length} file${filePlural} selected`;

      imagesSelectedPreUpload.innerHTML = '';
      (e.target.files).forEach((file) => {
        imagesSelectedPreUpload.innerHTML += `
          <div class="direct-upload direct-upload--pending">
            <div class="direct-upload__progress" style="width: 0%"></div>
            <span class="direct-upload__filename">${file.name}</span>
          </div>
        `;
      });
    });
  }
}
