/* Copyright © 2021 Ledgard Jepson Ltd. All rights reserved. */

import 'vanilla-cookieconsent';
import { Controller } from '@hotwired/stimulus';

/*
IMPORTANT:
---------

This solution uses Google Consent Mode.
The GTM Template (and additional GTM steps) MUST also be added to the GTM container as a required
counterpart to this.
Google dataLayer/gtag are defined with the google tag manager head script, which this relies upon.

NOTES:

'CookieConsent' is the name of the plugin package used.
'CookieController' is the name of this wrapping Stimulus controller.
This naming difference is intentional, to keep clear where cookieConsent stops, and our code starts.

The big benefit of having the cookie consent work in this controller is that injected/mutated
links 'just work', due to Stimulus' automatic handling of mutated content.

When editing permission settings here, you will also need to consider (and possibly amend) the
Ruby counterpart (cookie_control.rb).

APPROACH:

Whether e.g. video cookies are allowed - is placed into the HTML at render as values on e.g. the
modals controller.  If the user changes their cookie consent - that dispatches an event - which
e.g. modals controller listens for, and will adjust its values accordingly for whether video
cookies allowed.

This arrangement is intended to be quite fault-tolerant - as if the event dispatch/listening
breaks down, then a page refresh will suffice.
*/

// Connects to data-controller="cookie"
export default class extends Controller {
  static values = {
    revision: Number,
    cookieExpiration: Number,
    delay: { type: Number, default: 500 },
  };

  // Translations are kept out of values just because they're so large, to less bloat the body tag.
  // Instead - we attach them to a target, and parse JSON ourselves manually.
  static targets = [
    'translations',
  ];

  connect() {
    this.consoleLog('cookie controller connected');

    const translations = JSON.parse(this.translationsTarget.dataset.translations);

    this.cookieConsent = this.createCookieConsent({
      revision: this.revisionValue,
      cookieExpiration: this.cookieExpirationValue,
      delay: this.delayValue,
      translations,
    });
  }

  showSettings(event) {
    event.preventDefault();
    this.cookieConsent.showSettings();
  }

  /* eslint-disable-next-line class-methods-use-this, no-unused-vars */
  consoleLog(...args) {
    // console.log(...args); /* eslint-disable-line no-console */
  }

  videoCookiesAllowed(cookieConsent = this.cookieConsent) {
    return cookieConsent.allowedCategory('functional');
  }

  updateGTMConsent() {
    this.consoleLog('updateGTMConsent:...');
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: 'client-consent-update' });
  }

  dispatchCookieConsentUpdatedEvent(cookieConsent) {
    // Dispatch event for other code/controllers to act on as required.
    const event = new CustomEvent(
      'cookieConsentUpdated',
      {
        detail: {
          videoCookiesAllowed: this.videoCookiesAllowed(cookieConsent),
        },
      },
    );
    window.dispatchEvent(event);
  }

  createCookieConsent({
    translations,
    revision,
    cookieExpiration,
    delay,
  }) {
    this.consoleLog('createCookieConsent');

    const cookieConsent = window.initCookieConsent();
    const t = translations.cookie_consent; // Concise version, an alias if you like.

    function decodeHTML(html) {
      const txt = document.createElement('textarea');
      txt.innerHTML = html;
      return txt.value;
    }

    const context = this;

    cookieConsent.run({
      autoclear_cookies: true,
      remove_cookie_tables: true,
      force_consent: true,
      hide_from_bots: true,
      use_rfc_cookie: true,
      cookie_expiration: cookieExpiration, // days
      delay, // ms
      revision, // default of 0
      onFirstAction() {
        context.consoleLog('You completed the first action of cookie preferences.');
        context.updateGTMConsent();
        context.dispatchCookieConsentUpdatedEvent(cookieConsent);
      },
      onChange() {
        context.consoleLog('You just changed your preferences');
        context.updateGTMConsent();
        context.dispatchCookieConsentUpdatedEvent(cookieConsent);
      },
      current_lang: 'en', // Cosmetic (in console).  We're passing in translations to same one lang.
      languages: {
        en: {
          consent_modal: {
            title: t.consent_modal.title,
            description: `${decodeHTML(t.consent_modal.description)} {{revision_message}}`,
            revision_message: decodeHTML(t.consent_modal.revision_message),
            primary_btn: {
              text: t.consent_modal.accept_all,
              role: 'accept_all', // 'accept_selected' or 'accept_all'
            },
            secondary_btn: {
              text: t.consent_modal.settings,
              role: 'settings', // 'settings' or 'accept_necessary'
            },
          },
          settings_modal: {
            title: t.settings_modal.title,
            save_settings_btn: t.settings_modal.save_settings_btn,
            accept_all_btn: t.settings_modal.accept_all_btn,
            reject_all_btn: t.settings_modal.reject_all_btn, // optional, [v.2.5.0 +]
            close_btn_label: t.settings_modal.close_btn_label,
            cookie_table_headers: [
              { col1: 'cookie name' }, // Need to keep this, though not shown, so autoclear works.
            ],
            blocks: [
              {
                title: t.settings_modal.blocks.intro.title,
                description: decodeHTML(t.settings_modal.blocks.intro.description),
              },
              {
                title: t.settings_modal.blocks.necessary.title,
                description: t.settings_modal.blocks.necessary.description,
                toggle: {
                  value: 'necessary',
                  enabled: true,
                  readonly: true,
                },
                cookie_table: [
                  { col1: '_cms_session' },
                  { col1: 'user_credentials' },
                ],
              },
              {
                title: t.settings_modal.blocks.functional.title,
                description: t.settings_modal.blocks.functional.description,
                toggle: {
                  value: 'functional',
                  enabled: false,
                  readonly: false,
                },
                cookie_table: [
                  { col1: 'CONSENT' },
                  { col1: 'YSC' },
                  { col1: '_abexps' },
                  { col1: '__pdst' },
                  { col1: 'vuid' },
                  { col1: 'player' },
                ],
              },
              {
                title: t.settings_modal.blocks.analytics.title,
                description: t.settings_modal.blocks.analytics.description,
                toggle: {
                  value: 'analytics',
                  enabled: false,
                  readonly: false,
                },
                cookie_table: [
                  {
                    col1: '^_ga',
                    is_regex: true,
                    path: '/',
                    domain: '.ledgardjepson.net',
                  },
                  { col1: '_gat' },
                  { col1: '_gid' },
                ],
              },
              {
                title: t.settings_modal.blocks.advertising.title,
                description: t.settings_modal.blocks.advertising.description,
                toggle: {
                  value: 'advertising',
                  enabled: false,
                  readonly: false,
                },
                cookie_table: [
                  {
                    col1: 'ln_or',
                    path: '/',
                    domain: '.cms.sandbox.ledgardjepson.net',
                  },
                  {
                    col1: '_fbp',
                    path: '/',
                    domain: '.ledgardjepson.net',
                  },
                ],
              },
            ],
          },
        },
      },
    });

    return cookieConsent;
  }
}
