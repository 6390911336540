import FromPath from './from-path';
import FromScrape from './from-scrape';
import Video from './video';

function loaderForType(type) {
  if (type === 'path') {
    return new FromPath();
  }

  if (type === 'scrape') {
    return new FromScrape();
  }

  if (type === 'video') {
    return new Video();
  }

  return undefined;
}

export {
  loaderForType,
  FromPath,
  FromScrape,
  Video,
};
