import { Controller } from '@hotwired/stimulus';

// This pushes events to dataLayer for Google Analytics (if consented).

// Connects to data-controller="google-analytics-event"
export default class extends Controller {
  static values = {
    parameters: Object,
  };

  connect() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(this.parametersValue);
  }
}
