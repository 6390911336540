// This is a modified version of the DefaultRenderer class, to allow our custom styling.
// https://github.com/googlemaps/js-markerclusterer/blob/main/src/renderer.ts

/* eslint class-methods-use-this: "off" */

class CustomRenderer {
  render({ count, position }, stats) {
    const { google } = window;

    // change color if this cluster has more markers than the mean cluster
    const color = count > Math.max(100, stats.clusters.markers.mean) ? '#ff5d00' : '#fdc715';
    // create svg url with fill color
    const svg = window.btoa(`
<svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
<circle cx="120" cy="120" opacity=".9" r="70" />
<circle cx="120" cy="120" opacity=".6" r="90" />
<circle cx="120" cy="120" opacity=".3" r="110" />
</svg>`);
    // create marker using svg icon
    return new google.maps.Marker({
      position,
      icon: {
        url: `data:image/svg+xml;base64,${svg}`,
        scaledSize: new google.maps.Size(45, 45),
      },
      label: {
        text: String(count),
        color: 'rgba(0,0,0,0.9)',
        fontSize: '12px',
        fontWeight: 'bold',
      },
      title: `Cluster of ${count} markers`,
      // adjust zIndex to be above other markers
      zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
    });
  }
}

export default CustomRenderer;
