/* Copyright © 2022 Ledgard Jepson Ltd. All rights reserved. */

import { Controller } from '@hotwired/stimulus';

/* eslint class-methods-use-this: "off" */

// Connects to data-controller="scrolling"
export default class extends Controller {
  static targets = [
    'scrollIntoView',
  ];

  static values = {
    topOffset: { type: Number, default: 160 },
  };

  // The below is a TargetConnected callback method.
  scrollIntoViewTargetConnected(targetElement) {
    targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  scrollUpToTopOfThis(event) {
    // Use-case: Pagination - when load in new results page when viewing page bottom, to scroll up.
    const viewportOffsetTop = event.target.getBoundingClientRect().top;
    if (viewportOffsetTop > 0) { return; } // Would be scrolling down - ignore.

    // Note on offset: We deduct this.topOffsetValue so top of content has some space above.
    // Enough space to mitigate the height of the various sticky headers across breakpoints.
    // Or display page title when scrollFromTop distance is not enough to invoke the sticky header.
    window.scrollTo({
      behavior: 'smooth',
      top:
        viewportOffsetTop
        - document.body.getBoundingClientRect().top
        - this.topOffsetValue,
    });
  }
}
