/* Copyright © 2023 Ledgard Jepson Ltd. All rights reserved. */

import { Controller } from '@hotwired/stimulus';

/* eslint class-methods-use-this: "off" */

// Connects to data-controller="pagination"
export default class extends Controller {
  updateCanonical(event) {
    const newUrl = event.detail.newFrame.baseURI;
    const canonicalTag = document.querySelector("link[rel='canonical']");
    if (canonicalTag !== undefined) {
      canonicalTag.href = newUrl;
    }
  }
}
