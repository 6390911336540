import Helpers from '../lib/helpers';

class ToggleTip {
  constructor({ selector }) {
    this.selector = document.querySelectorAll(selector);
    if (!this.selector) { return null; }

    // Run toggletip
    this.run();
  }

  run() {
    // For each toggletip button on the page
    Helpers.forEach(this.selector, (toggletip) => {
      // Store the message title from the data-toggletip-title attribute
      const tipTitle = toggletip.getAttribute('data-toggletip-title');

      // Store the message body (if there is one)
      let tipBody = '';
      if (toggletip.getAttribute('data-toggletip-body')) {
        const tipBodyText = toggletip.getAttribute('data-toggletip-body');
        tipBody = `<p>${tipBodyText}</p>`;
      }

      // Get the live region element
      const liveRegion = toggletip.nextElementSibling;

      if (toggletip.nodeName !== 'BUTTON') {
        console.error('Toggletip buttons need to be <button> elements.');
        return;
      }

      // Toggle the button's message visibility on click of each button
      toggletip.addEventListener('click', (event) => {
        ToggleTip.checkEdgeDistance(event, tipTitle, tipBody, toggletip, liveRegion);
      });

      // Close on outside click
      document.addEventListener('click', (event) => {
        ToggleTip.outsideClick(event, toggletip, liveRegion);
      });

      // Remove toggletip on ESC
      toggletip.addEventListener('keydown', (event) => {
        ToggleTip.escapeClick(event, toggletip, liveRegion);
      });
    });
  }

  static escapeClick(e, toggletip, liveRegion) {
    if ((e.keyCode || e.which) === 27) {
      ToggleTip.clearToggleTip(toggletip, liveRegion);
    }
  }

  static outsideClick(e, toggletip, liveRegion) {
    // if this current button is not the thing that was clicked
    // console.log("outsideClick e.target = ", e.target)
    if (toggletip !== e.target) {
      ToggleTip.clearToggleTip(toggletip, liveRegion);
    }
  }

  static clearToggleTip(toggletip, liveRegion) {
    // Clear toggletip content
    liveRegion.innerHTML = '';
    // so if resized between next click it recalculates the class
    Helpers.removeClass(liveRegion, 'display-on-left');
    Helpers.removeClass(liveRegion, 'display-on-right');
    Helpers.removeClass(toggletip, 'active');
  }

  static checkEdgeDistance(event, tipTitle, tipBody, toggletip, liveRegion) {
    // Check toggletip button distance from edge
    const offset = event.target.getBoundingClientRect();
    const distanceFromLeftEdge = offset.left;
    const distanceFromRightEdge = window.innerWidth - offset.right;

    // If this toggletip is already open, close it
    if (Helpers.hasClass(event.target, 'active')) {
      // Close it instead of opening it
      ToggleTip.clearToggleTip(toggletip, liveRegion);
      // Stop here
      return;
    }

    // console.log("offset.width = ", offset.width);
    // console.log("distanceFromRightEdge = ", distanceFromRightEdge);
    // console.log("distanceFromLeftEdge = ", distanceFromLeftEdge);

    // Define distance from edge to change sides at
    // (.toggletip__bubble width / 2) - (.toggletip__button width / 2)
    const distanceFromEdgeToSwapSidesAt = 115;

    // Decide whether to display toggle tip on left
    if (distanceFromRightEdge < distanceFromEdgeToSwapSidesAt) {
      Helpers.addClass(liveRegion, 'display-on-left');
    }

    // Decide whether to display toggle tip on Right
    if (distanceFromLeftEdge < distanceFromEdgeToSwapSidesAt) {
      Helpers.addClass(liveRegion, 'display-on-right');
    }

    // Add text into the bubble
    liveRegion.innerHTML = '';
    window.setTimeout(() => {
      liveRegion.innerHTML = `<div class="toggletip__bubble"><h4>${tipTitle}</h4>${tipBody}</div>`;
      // Add active class
      Helpers.addClass(event.target, 'active');
    }, 100);
  }
}

export default ToggleTip;
