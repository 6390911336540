/* Copyright © 2023 Ledgard Jepson Ltd. All rights reserved. */

import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import debounce from 'lodash/debounce';

// Connects to data-controller="clearance-filter"
export default class extends Controller {
  static targets = [
    'form',
    'type',
    'size',
    'condition',
  ];

  filter() {
    const applyDebouncer = debounce(this.submit.bind(this), 250);
    applyDebouncer();
  }

  submit() {
    Turbo.visit(
      this.frameUrl(),
      {
        frame: 'clearance_frame',
        action: 'advance', //  Currently doesn't work, see below.
      },
    );
    // Manually update URL, due to https://github.com/hotwired/turbo/issues/489.
    window.history.replaceState(null, null, this.frameUrl());
  }

  frameUrl() {
    let url = this.formTarget.action;

    const searchParams = new URLSearchParams();

    const targets = [this.typeTarget, this.sizeTarget, this.conditionTarget];
    targets.forEach((target) => {
      if (target.value !== '') {
        searchParams.set(target.name, target.value);
      }
    });

    if (searchParams.toString().length > 0) {
      url += `?${searchParams.toString()}`;
    }

    return url;
  }
}
