import Counter from '../lib/counter';

class CountUp {
  constructor({ target, endVal }) {
    this.target = target;
    this.endVal = endVal;
    if (!this.target || !this.endVal) { return null; }

    this.createCounter();

    return this;
  }

  createCounter() {
    // Count[er/up].js options:
    const options = {
      useEasing: true,
      useGrouping: false, // 1,000 as opposed to 1000
      separator: ',',
      decimal: '.',
      prefix: '',
      suffix: '',
    };

    this.counter = new Counter(
      this.target,
      0,
      this.endVal,
      0,
      4,
      options,
      this.endVal.length,
    );

    this.counter.start();
  }

  counterVisibilityHandler() {
    this.onVisibilityChanged((visible) => {
      if (visible) {
        this.counter.reset();
        this.counter.start();
      }
    });
  }

  // Check if element visibility changed
  onVisibilityChanged(callback) {
    const visible = CountUp.isElementVisible(this.target);

    if (visible !== this.previousVisible) {
      this.previousVisible = visible;
      if (typeof callback === 'function') {
        callback(visible);
      }
    }
  }

  // Checks if element is within the viewport
  static isElementVisible(el) {
    const rect = el.getBoundingClientRect();
    const vWidth = window.innerWidth || document.documentElement.clientWidth;
    const vHeight = window.innerHeight || document.documentElement.clientHeight;
    const efp = function efp(x, y) { return document.elementFromPoint(x, y); };

    // Return false if it's not in the viewport
    if (rect.right < 0
        || rect.bottom < 0
        || rect.left > vWidth
        || rect.top > vHeight) {
      return false;
    }

    // Return true if any of its four corners are visible
    return (
      el.contains(efp(rect.left, rect.top))
      || el.contains(efp(rect.right, rect.top))
      || el.contains(efp(rect.right, rect.bottom))
      || el.contains(efp(rect.left, rect.bottom))
    );
  }
}

export default CountUp;
