import Helpers from '../lib/helpers';

class TabbedInterface {
  constructor({ selector }) {
    this.selector = document.querySelectorAll(selector);
    if (!this.selector) { return null; }

    this.slideInOffers = document.querySelectorAll('[data-slide-in-offer]');
    window.TabbedInterface = this;
  }

  initialise(slideIn = false) {
    Helpers.forEach(this.selector, (el, i) => {
      const tabbed = el;
      const tabLevel = tabbed.getAttribute('data-tabbed-interface-level');
      const tablist = tabbed.querySelector('[data-tabbed-interface] > ul');
      const tabs = tablist.querySelectorAll('a');

      const panels = tabbed.querySelectorAll(`[data-tabbed-interface__level${tabLevel}] > section`);

      // The tab switching function
      const switchTab = (oldTab, newTab) => {
        newTab.focus();
        // Make the active tab focusable by the user (Tab key)
        newTab.removeAttribute('tabindex');
        // Set the selected state
        newTab.setAttribute('aria-selected', 'true');
        oldTab.removeAttribute('aria-selected');
        oldTab.setAttribute('tabindex', '-1');
        // Get the indices of the new and old tabs to find the correct
        // tab panels to show and hide
        const index = Array.prototype.indexOf.call(tabs, newTab);
        const oldIndex = Array.prototype.indexOf.call(tabs, oldTab);
        panels[oldIndex].hidden = true;
        panels[index].hidden = false;

        // Update address/URL for active tab
        const { hash } = (new URL(newTab.href));
        const tabFragment = hash.replace('#', '');
        window.history.pushState(null, null, hash);

        // Show/hide slide-in-offers
        if ((document.cookie.search('cc_cookie') !== -1 || slideIn)) {
          const slideInOffer = document.querySelector(
            [`[data-slide-in-offer][data-tab-slug="${tabFragment}"]`],
          );
          if (slideInOffer) {
            TabbedInterface.showSlideInOffer(slideInOffer);
          }
          this.hideAllSlideInOffersExcept(slideInOffer);
        }

        // Update new tab's glide galleries
        const tabGalleries = panels[index].querySelectorAll('[data-js="gallery-slides"]');
        if (tabGalleries) {
          Helpers.forEach(tabGalleries, (gallery) => {
            let glideInstanceName;
            if (gallery && gallery.dataset.glideId) {
              glideInstanceName = `glide-${gallery.dataset.glideId}`;
            }
            // Now the tab's glide sliders are not hidden or display: none - it should
            // successfully show if we call .update():
            const glide = window[glideInstanceName];
            if (glide) {
              glide.update();
              glide.recalculateNavArrowPosition();
            }
          });
        }
      };

      // Add the tablist role to the first <ul> in the .tabbed container
      tablist.setAttribute('role', 'tablist');

      // Add semantics to remove user focusability for each tab
      Helpers.forEach(tabs, (tab, i) => {
        tab.setAttribute('role', 'tab');
        tab.setAttribute('id', `tab-${tabLevel}-${i + 1}`);
        tab.setAttribute('tabindex', '-1');
        tab.parentNode.setAttribute('role', 'presentation');

        // Handle clicking of tabs for mouse users
        tab.addEventListener('click', (e) => {
          e.preventDefault();
          const currentTab = tablist.querySelector('[aria-selected]');
          if (e.currentTarget !== currentTab) {
            switchTab(currentTab, e.currentTarget);
          }
        });

        // Handle keydown events for keyboard users
        tab.addEventListener('keydown', (e) => {
          // Get the index of the current tab in the tabs node list
          const index = Array.prototype.indexOf.call(tabs, e.currentTarget);
          // Work out which key the user is pressing and
          // Calculate the new tab's index where appropriate
          const dir = e.which === 37 ? index - 1 : e.which === 39 ? index + 1 : e.which === 40 ? 'down' : null;
          if (dir !== null) {
            e.preventDefault();
            // If the down key is pressed, move focus to the open panel,
            // otherwise switch to the adjacent tab
            dir === 'down' ? panels[i].focus() : tabs[dir] ? switchTab(e.currentTarget, tabs[dir]) : void 0;
          }
        });
      });

      // Add tab panel semantics and hide them all
      Helpers.forEach(panels, (panel, i) => {
        panel.setAttribute('role', 'tabpanel');
        panel.setAttribute('tabindex', '-1');
        const id = panel.getAttribute('id');
        panel.setAttribute('aria-labelledby', tabs[i].id);
        panel.hidden = true;
      });

      const tabFragmentFromUrlString = (urlString, level) => {
        const { hash } = (new URL(urlString));
        const tabFragment = hash.replace('#', '');
        if (tabFragment) {
          const items = tabFragment.split('--');
          return items[level - 1];
        }
        return '';
      };

      // Find which tab the URL is requesting to be shown, if no match found then show first.
      let tabIndexToInitiallyShow = 0;
      const tabLevelInt = parseInt(tabLevel, 10);
      const tabFragment = tabFragmentFromUrlString(window.location, tabLevelInt);
      Helpers.forEach(tabs, (tab, index) => {
        const parentTabSlug = tab.dataset.parentTab;
        if (tabFragment === tabFragmentFromUrlString(tab.href, tabLevelInt)) {
          if (
            (tabLevelInt === 1)
            || ((tabLevelInt === 2)
                && (parentTabSlug === tabFragmentFromUrlString(window.location, 1)))
          ) {
            // We have a match!
            tabIndexToInitiallyShow = index;
          }
        }
      });

      // Initially activate the required tab and reveal the required tab panel.
      tabs[tabIndexToInitiallyShow].removeAttribute('tabindex');
      tabs[tabIndexToInitiallyShow].setAttribute('aria-selected', 'true');
      panels[tabIndexToInitiallyShow].hidden = false;

      if (document.cookie.search('cc_cookie') !== -1 || (window.env !== 'production' && slideIn)) {
        // Show slide-in-offer for initially selected tab(s) (could be two - level 1 and 2):
        const slideInOffer = document.querySelector(
          [`[data-slide-in-offer][data-tab-slug="${tabs[tabIndexToInitiallyShow].dataset.tabButtonFor}"]`],
        );
        if (slideInOffer) {
          TabbedInterface.showSlideInOffer(slideInOffer);
        }
      }
    });
  }

  hideAllSlideInOffersExcept(slideInOffer) {
    this.slideInOffers.forEach((item) => {
      if (item !== slideInOffer) {
        TabbedInterface.hideSlideInOffer(item);
      }
    });
  }

  static showSlideInOffer(slideInOffer) {
    if (!TabbedInterface.previouslyDismissed(slideInOffer)) {
      const sIO = slideInOffer;
      sIO.dataset.state = 'open';

      // Handle clicking Close ('X') button of slide in offer:
      const closeButton = slideInOffer.querySelector('[data-close-button]');
      if (closeButton) {
        if (sIO.dataset.closeListener !== 'added') {
          closeButton.addEventListener('click', (e) => {
            e.preventDefault();
            TabbedInterface.dismiss(slideInOffer);
          });
          sIO.dataset.closeListener = 'added';
        }
      }
    }
  }

  static hideSlideInOffer(slideInOffer) {
    const sIO = slideInOffer;
    sIO.dataset.state = 'closed';
  }

  static dismiss(slideInOffer) {
    TabbedInterface.hideSlideInOffer(slideInOffer);

    TabbedInterface.writeCookie(
      TabbedInterface.cookieName(slideInOffer),
      'dismissed',
      7,
    );
  }

  static previouslyDismissed(slideInOffer) {
    return TabbedInterface.readCookie(TabbedInterface.cookieName(slideInOffer));
  }

  static cookieName(slideInOffer) {
    return `slide-in-offer_${TabbedInterface.uniqueIdentifier(slideInOffer)}`;
  }

  static uniqueIdentifier(slideInOffer) {
    const { tabSlug, pageSlug } = slideInOffer.dataset;
    return `${pageSlug}_${tabSlug}`;
  }

  static writeCookie(name, value, days) {
    let expires = '';

    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + 24 * days * 60 * 60 * 1e3);
      expires = `; expires=${date.toGMTString()}`;
    }

    document.cookie = `${name}=${value}${expires}; path=/`;
  }

  static readCookie(name) {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');

    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];

      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }

      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }

    return null;
  }
}

export default TabbedInterface;
