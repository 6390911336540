import { Controller } from '@hotwired/stimulus';
import { MarkerClusterer } from '@googlemaps/markerclusterer';

/* eslint class-methods-use-this: "off" */
/* eslint no-underscore-dangle: "off" */

// Connects to data-controller="map"
export default class extends Controller {
  static targets = [
    'mapHolder',
    'latitude',
    'longitude',
  ];

  static values = {
    locations: Array,
    cluster: { type: Boolean, default: false },
    infowindows: { type: Boolean, default: true },
    fitBoundsToMarkers: { type: Boolean, default: true },
    mapOptions: Object,
    markerIconUrl: String,
  }

  initialize() {
    this.markers = [];
  }

  connect() {
    // This normally won't be required - as the google maps script tag uses `async defer`, and we
    // use a callback event, but if that changes to be a blocking load before this runs, this will
    // still work fine in that html/dom scenario.
    if (typeof (google) !== 'undefined') {
      this.initMap();
    }
  }

  initMap() {
    this.createMap();
    this.addMarkers();
  }

  mapOptions() {
    // Note on options:
    // These default options may be amended as part of site set-up.
    // For different use-cases within the site requiring different styling:  The default options may
    // be overridden as required in the HTML via the mapOptions Stimulus value, to hopefully lessen
    // the need for conditional logic to be added to this controller for the different use-cases.

    const { google } = window;

    const defaultMapOptions = {
      center: { lat: 53, lng: -1.5 },
      zoom: 7,
      minZoom: 1,
      maxZoom: 16,
      mapTypeId: 'roadmap',
      mapTypeControl: true,
      scaleControl: true,
      streetViewControl: true,
      rotateControl: true,
      fullscreenControl: true,
      zoomControl: true,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
        mapTypeIds: ['roadmap', 'satellite', 'hybrid'],
        position: google.maps.ControlPosition.RIGHT_TOP,
      },
      styles: [
        { elementType: 'geometry', stylers: [{ saturation: -100 }] },
        { elementType: 'labels.text.stroke', stylers: [{ saturation: -100 }] },
        { elementType: 'labels.text.fill', stylers: [{ saturation: -100 }] },
      ],
    };

    const options = { ...defaultMapOptions, ...this.mapOptionsValue };
    this.consoleLog('mapOptions are:', options);

    return options;
  }

  markerIcon() {
    const { google } = window;

    return {
      url: this.markerIconUrlValue,
      size: new google.maps.Size(25, 37.5),
      scaledSize: new google.maps.Size(25, 37.5),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(12.5, 37.5),
    };
  }

  createMap() {
    this.consoleLog('createMap()');
    const { google } = window;

    this.map = new google.maps.Map(
      this.mapHolderTarget,
      this.mapOptions(),
    );

    return this.map;
  }

  addMarkers() {
    this.consoleLog('addMarkers()', this.locationsValue);
    const { google } = window;
    const markerIcon = this.markerIcon();

    const bounds = new google.maps.LatLngBounds();

    this.locationsValue.forEach((location) => {
      const latLng = {
        lat: parseFloat(location.latitude),
        lng: parseFloat(location.longitude),
      };

      const marker = new google.maps.Marker({
        position: latLng,
        map: this.map,
        icon: markerIcon,
      });

      if (this.infowindowsValue) {
        this.addInfowindow(location, marker);
      }

      this.markers.push(marker);

      bounds.extend(latLng);
    });

    if (this.clusterValue) {
      if (this.markerClusterer !== undefined) {
        this.markerClusterer.clearMarkers();
      }
      this.markerClusterer = new MarkerClusterer({ map: this.map, markers: this.markers });
    }

    if (this.fitBoundsToMarkersValue && this.locationsValue.length > 0) {
      this.map.fitBounds(bounds);
    }
  }

  addInfowindow(location, marker) {
    const { google } = window;

    const infoWindow = new google.maps.InfoWindow({
      content: location.infowindowContent,
    });
    marker.addListener('click', () => {
      infoWindow.open(this.map, marker);
    });
  }

  /* eslint-disable-next-line class-methods-use-this, no-unused-vars */
  consoleLog(...args) {
    // eslint-disable-next-line no-console
    // console.log('MapController:   ', ...args); // Uncomment this line to assist debugging/development.
  }
}
