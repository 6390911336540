import Helpers from '../lib/helpers';

class AddUserAgentClasses {
  constructor() {
    /**
    * detect IE
    * and add a specific class to the body
    * function modified from this: http://stackoverflow.com/questions/19999388/check-if-user-is-using-ie-with-jquery
    */
    const ua = window.navigator.userAgent;
    let a = 'not-ie';
    let b = '';
    const msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older
      a = 'msie';
      b = 'ie10';
    }

    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11
      a = 'msie';
      b = 'ie11';
    }

    const edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+)
      a = 'edge';
      b = `ie${parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10)}`;
    }

    const body = document.querySelector('body');

    // other browser
    if (a !== '') {
      Helpers.addClass(body, a);
    }

    if (b !== '') {
      Helpers.addClass(body, b);
    }

    // Firefox
    if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
      // Do Firefox-related activities
      Helpers.addClass(body, 'firefox');
    }

    const cvUploadInput = document.querySelector('[data-cv-file]');
    const coverLetterUploadInput = document.querySelector('[data-covering-letter-file]');

    if(cvUploadInput) {
      cvUploadInput.addEventListener('change', function () {
        var fauxInput = document.querySelector('[data-fake-input-cv]'),
            value = this.value;

        fauxInput.value = value.split(/(\\|\/)/g).pop();
      });
    }

    if (coverLetterUploadInput) {
      coverLetterUploadInput.addEventListener('change', function () {
        var fauxInput = document.querySelector('[data-fake-input-cover-letter]'),
            value = this.value;

        fauxInput.value = value.split(/(\\|\/)/g).pop();
      });
    }
  }
}

export default AddUserAgentClasses;
