import Glide from '@glidejs/glide';
import Helpers from '../lib/helpers';

class LogoSlider {
  constructor({
    selector,
    logoGalleriesArray = [],
  }) {
    this.selector = document.querySelectorAll(selector);
    // Only run if selector is on page
    if (!this.selector) { return null; }
    // Setup array for later
    this.logoGalleriesArray = logoGalleriesArray;
    // Start our logo slider functions
    this.initSlider();
  }

  initSlider() {
    Helpers.forEach(this.selector, (el, i) => {
      this.mountSlider(el, i);
    });
  }

  mountSlider(el, i) {
    Helpers.addClass(el, 'logo-slider--active');
    const currentLogoSlider = el;
    this.logoGalleriesArray.push(currentLogoSlider);

    this.logoGalleriesArray[i] = new Glide(el, {
      type: 'carousel',
      gap: 0,
      animationDuration: 1000,
      autoplay: 3000,
      autoheight: true,
      hoverpause: false,
      swipeThreshold: false,
      dragThreshold: false,
      perView: 6,
      focusAt: '0',
      breakpoints: {
        1199: {
          perView: 5,
          autoplay: 3000,
          focusAt: 'center',
        },
        767: {
          perView: 3,
          autoplay: 3000,
          focusAt: 'center',
        },
        600: {
          perView: 1,
          autoplay: 3000,
          focusAt: 'center',
        },
      },
    });

    this.logoGalleriesArray[i].mount();
  }
}

export default LogoSlider;
