class Video {
  /* eslint class-methods-use-this: "off" */

  async load(request) {
    if (!request.videoUrl) {
      return { ok: false, error: 'Video URL not passed' };
    }

    const html = `
      <div class="video__wrap video__wrap--16x9">
        <iframe
          src="${request.videoUrl}"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
        ></iframe>
      </div>
    `;
    return { ok: true, html };
  }
}

export default Video;
