import Helpers from '../lib/helpers';

class TableFade {
  constructor({ selector }) {
    this.selector = selector;

    if (!this.selector) { return null; }

    // select all tables
    const tables = document.querySelectorAll(`[${this.selector}] table`);

    // if there are tables present
    if (tables) {
      // loop through each table and attach event listeners
      Helpers.forEach(tables, (el) => {
        let wrapper = el.parentNode;

        // On page load if this table has overflowing content...
        // set the attribute which adds the gradient fade
        if (el.clientWidth < el.scrollWidth) {
          wrapper.classList.add('table-fade-present');
        } else {
          wrapper.classList.remove('table-fade-present');
        }

        // On scroll of the current table
        el.addEventListener('scroll', () => {
          // Work out how many pixels are left to scroll
          const scrollPixelsLeft = el.scrollWidth - el.scrollLeft - el.clientWidth;

          // Remove the gradient fade if close to the end of the scroll distance
          if (scrollPixelsLeft < 30) {
            wrapper.classList.add('table-fade-inactive');
          } else {
            wrapper.classList.remove('table-fade-inactive');
          }
        }, { passive: true });

        // On resize of the window
        // Check if table content is still overflowing
        // Remove fade if not
        // add fade if so
        window.addEventListener('resize', () => {
          if (el.clientWidth < el.scrollWidth) {
            wrapper.classList.add('table-fade-present');
          } else {
            wrapper.classList.remove('table-fade-present');
          }
        }, { passive: true });
      });
    }
  }
}

export default TableFade;
