import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="nice-uploader"
export default class extends Controller {
  static targets = ['filename', 'upload'];

  updateFilename() {
    this.filenameTarget.value = this.uploadTarget.value.split(/(\\|\/)/g).pop();
  }
}
