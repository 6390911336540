class Countdown {
  constructor({ endDate, element, updateDelay = 1000 }) {
    this.endDate = endDate;
    this.element = element || undefined;
    this.updateDelay = updateDelay;
  }

  startCountdown() {
    let days;
    let hours;
    let minutes;
    let seconds;

    const self = this;
    self.endDate = new Date(self.endDate.replace(/-/g, '/')).getTime();

    setInterval(() => {
      let startDate = new Date();
      startDate = startDate.getTime();

      let timeRemaining = parseInt((self.endDate - startDate) / 1000, 10);

      if (timeRemaining >= 0) {
        days = parseInt(timeRemaining / 86400, 10);
        timeRemaining %= 86400;

        hours = parseInt(timeRemaining / 3600, 10);
        timeRemaining %= 3600;

        minutes = parseInt(timeRemaining / 60, 10);
        timeRemaining %= 60;

        seconds = parseInt(timeRemaining, 10);

        const daysHtml = `${parseInt(days, 10)}<span>${Countdown.pluralise('Day', days)}</span>`;
        const hoursHtml = `${Countdown.leadingZero(hours)}<span>${Countdown.pluralise('Hour', hours)}</span>`;
        const minsHtml = `${Countdown.leadingZero(minutes)}<span>${Countdown.pluralise('Min', minutes)}</span>`;
        const secsHtml = `${Countdown.leadingZero(seconds)}<span>${Countdown.pluralise('Sec', seconds)}</span>`;

        self.element.querySelector('[data-time="days"]').innerHTML = daysHtml;
        self.element.querySelector('[data-time="hours"]').innerHTML = hoursHtml;
        self.element.querySelector('[data-time="minutes"]').innerHTML = minsHtml;
        self.element.querySelector('[data-time="seconds"]').innerHTML = secsHtml;
      }
    }, 1000);
  }

  static leadingZero(value) {
    return `0${value}`.slice(-2);
  }

  static pluralise(word, quantity) {
    if (quantity === 1) {
      return word;
    }

    return `${word}s`;
  }
}

export default Countdown;
