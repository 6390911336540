class Helpers {
  static ready(cb) {
    if (document.readyState !== 'loading') {
      cb();
    } else {
      document.addEventListener('DOMContentLoaded', cb);
    }
  }

  static forEach(array, callback, scope) {
    for (let i = 0; i < array.length; i += 1) {
      callback.call(scope, array[i], i); // passes back stuff we need
    }
  }

  // https://davidwalsh.name/javascript-debounce-function
  // Returns a function, that, as long as it continues to be invoked, will not
  // be triggered. The function will be called after it stops being called for
  // N milliseconds. If `immediate` is passed, trigger the function on the
  // leading edge, instead of the trailing.
  static debounce = (func, wait, immediate) => {
    let timeout;

    return function() {
      const context = this;
      const args = arguments;

      clearTimeout(timeout);

      timeout = setTimeout(function() {
        timeout = null;

        if (!immediate) {
          func.apply(context, args)
        }
      }, wait);

      if (immediate && !timeout) {
        func.apply(context, args);
      }
    };
  };

  static addClass(el, className) {
    if (el.classList) {
      el.classList.add(className);
    } else if (!Helpers.hasClass(el, className)) {
      el.className += ` ${className}`;
    }
  }

  static removeClass(el, className) {
    if (el.classList) {
      el.classList.remove(className);
    } else {
      el.className = el.className.replace(new RegExp(`\\b${className}\\b`, 'g'), '');
    }
  }

  static toggleClass(el, className) {
    if (el.classList) {
      el.classList.toggle(className);
    } else {
      let classes = el.className.split(' ');
      let existingIndex = classes.indexOf(className);

      if (existingIndex >= 0) {
        classes.splice(existingIndex, 1);
      } else {
        classes.push(className);
      }

      el.className = classes.join(' ');
    }
  }

  static hasClass(el, className) {
    return el.classList ? el.classList.contains(className) : new RegEx(`\\b${className}\\b`).test(el.className);
    // return this object so we can chain methods
    // TODO: SOLVE THE PROBLEM OF RETURNING THIS AFTER RETURNING THE hasClass
    // return this;
  }
}

export default Helpers;
