import { Controller } from '@hotwired/stimulus';

/* eslint class-methods-use-this: "off" */

// Connects to data-controller="collapsible"
export default class extends Controller {
  static targets = [
    'toggleButton',
    'collapsibleSection',
  ];

  static values = {
    collapsed: { type: Boolean, default: false },
  }

  connect() {
    // Initially all collapsible sections should be visible (for SEO), however
    // once javascript is loaded and runs - we may be required to collapse some:
    if (this.collapsedValue) {
      this.collapse();
    } else {
      // Explicitly expand collapsible
      // So the the correct classes and attributes are initially set
      this.expand();
    }
  }

  toggle() {
    if (this.collapsedValue) {
      this.expand();
    } else {
      this.collapse();
    }
  }

  collapse() {
    this.element.classList.remove('panel--open');
    this.toggleButtonTarget.setAttribute('aria-expanded', false);

    this.collapsibleSectionTarget.hidden = true;
    this.collapsedValue = true;
  }

  expand() {
    this.element.classList.add('panel--open');
    this.toggleButtonTarget.setAttribute('aria-expanded', true);

    this.collapsibleSectionTarget.hidden = false;
    this.collapsedValue = false;

    this.updateGallery();
  }

  updateGallery() {
    // Glide has trouble calculating the pixel distances for widths and transforms when hidden, so
    // we need to tell it to update when not hidden, so it can show reliably.
    const gallery = this.collapsibleSectionTarget.querySelector('[data-js="gallery-slides"]');

    let glideInstanceName;
    if (gallery && gallery.dataset.glideId) {
      glideInstanceName = `glide-${gallery.dataset.glideId}`;
    }

    const glide = window[glideInstanceName];
    if (glide) {
      glide.update();
      glide.recalculateNavArrowPosition();
    }
  }
}
