import { Controller } from '@hotwired/stimulus';
import Glide from '@glidejs/glide';

// Connects to data-controller="gallery"
export default class extends Controller {
  // Note:  Glide expects/requires the html to be decorated with specific 'data-glide-' attributes.
  // For our additional needs we use Stimulus targets to select elements, to keep concerns clean.

  static targets = [
    'track',
    'controls',
    'controlsNav',
    'caption', // Will usually be multiple - i.e. an array (plural)
  ]

  /*
  Glide Options:
  ==============
  Add a JSON object of glide option differences and/or additions to the defaults below, e.g.:
  data-gallery-glide-options-value="<%= JSON.generate({
    type: 'carousel',
    animationDuration: 500,
    autoplay: 2000
  }) %>"
  */
  static values = {
    glideOptions: Object,
    showNavBullets: { type: Boolean, default: false },
  }

  static glideDefaultOptions = {
    type: 'carousel',
    gap: 0,
    animationDuration: 1000,
    autoplay: 5500,
    perView: 1,
    autoheight: true,
    hoverpause: false,
  }

  connect() {
    this.consoleLog('gallery controller connected');

    const glideOptions = { ...this.constructor.glideDefaultOptions };
    Object.assign(glideOptions, this.glideOptionsValue);
    this.glideOptionsValue = glideOptions;
    this.consoleLog('Gallery options set: ', this.glideOptionsValue);

    if (this.showNavBulletsValue) {
      this.addNavBullets();
    }

    new Glide(this.element, this.glideOptionsValue).mount();

    // Move the navigation arrows and bullets (if present) to allow for captions.
    this.adjustPositionsForCaptions();

    // Add event listener for window resize to recalculate arrows / bullets.
    // Could/should be in HTML unstead.
    this.element.dataset.action = 'resize@window->gallery#adjustPositionsForCaptions:passive';
  }

  addNavBullets() {
    // Add nav bullets before we mount, so they have event listeners attached.

    if (!this.hasControlsNavTarget) { return; } // Missing expected html target.

    // Add a nav bullet inside the container for each glide slide
    for (let i = 0; i < this.trackTarget.firstElementChild.childElementCount; i += 1) {
      this.controlsNavTarget.innerHTML
        += `<button class="glide__bullet" data-glide-dir="=${[i]}"><span></span></button>`;
    }
  }

  adjustPositionsForCaptions() {
    this.consoleLog('calculateNewPositions');

    // Check for presence of captions first
    if (this.captionTargets.length === 0) return;

    const captionHeights = [];
    this.captionTargets.forEach((caption) => {
      const thisCaptionHeight = caption.getBoundingClientRect().height;
      captionHeights.push(thisCaptionHeight);
    });
    const max = captionHeights.reduce((a, b) => Math.max(a, b));

    // Move the gallery nav arrows to the horizontal center of gallery images
    const controls = this.controlsTarget;
    const distancePx = `${max}px`;
    controls.style.bottom = distancePx;

    if (this.showNavBulletsValue) {
      // select the bullet container
      const bulletNav = this.controlsNavTarget;

      // Move the bullets to 30px from the bottom of the image
      const bulletDistancePx = max + 30;
      bulletNav.style.top = `calc(100% - ${bulletDistancePx}px)`;
    }
  }

  /* eslint-disable-next-line class-methods-use-this, no-unused-vars */
  consoleLog(...args) {
    // eslint-disable-next-line no-console
    // console.log('gallery: ', ...args); // Uncomment this line to assist debugging/development.
  }
}
