class BuyBackEnquiry {
  constructor() {
    const form = document.querySelector('form[data-buy-back-enquiry]');
    if (form) {
      const enquiryTypeInputs = form.querySelectorAll("input[name='buy_back_enquiry[enquiry_type]']");
      enquiryTypeInputs.forEach((i) => {
        i.addEventListener(
          'change',
          () => {
            BuyBackEnquiry.toggleFields(form);
          },
          { passive: true },
        );
      });
    }
  }

  static toggleFields(form) {
    const selector = "input[name='buy_back_enquiry[enquiry_type]']";
    const enquiryTypeInputs = form.querySelectorAll(selector);
    const enquiryTypeInputChecked = form.querySelector(`${selector}:checked`);

    const allTypes = Array.from(enquiryTypeInputs).map((z) => z.value);
    const checkedType = enquiryTypeInputChecked.value;

    allTypes.forEach((type) => {
      form.querySelectorAll(`[data-enquiry-type-${type}`).forEach((d) => {
        if (type === checkedType) {
          d.style.display = 'block';
        } else {
          d.style.display = 'none';
        }
      });
    });
  }
}

export default BuyBackEnquiry;
