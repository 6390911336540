class FromPath {
  /* eslint class-methods-use-this: "off" */

  async load(request) {
    if (!request.path) {
      return { ok: false, error: 'Path not specified' };
    }

    const response = await fetch(request.path);

    if (response.ok) {
      const html = await response.text();
      return { ok: true, html };
    }

    return { ok: false, error: response.status };
  }
}

export default FromPath;
