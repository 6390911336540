/* eslint no-new: "off" */

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import modernizr from 'modernizr';

// IE11 polyfills - as they don't work when within ie11 pack
// Perhaps these polyfills could be put in IE11's global scope where the
// ie11 pack is called?  Instead of being here.
import '../../javascripts/lib/ie11-polyfills/element-qsa-scope';
import '../../javascripts/lib/ie11-polyfills/nodelist-foreach';
import '../../javascripts/lib/ie11-polyfills/closest';
import '../../javascripts/lib/ie11-polyfills/details-polyfill';
import '../../javascripts/lib/ie11-polyfills/better-dom';
import '../../javascripts/lib/ie11-polyfills/better-dateinput-polyfill';
import '../../javascripts/lib/ie11-polyfills/url-polyfill';

import Rails from '@rails/ujs';
import { Turbo } from '@hotwired/turbo-rails';
import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';
import * as ActiveStorage from '@rails/activestorage';

import lazySizes from 'lazysizes';
import Helpers from '../../javascripts/lib/helpers';
import AddUserAgentClasses from '../../javascripts/application/add-user-agent-classes';
import Header from '../../javascripts/application/header';
import Search from '../../javascripts/application/search';
import StickyNav from '../../javascripts/application/sticky-nav';
import GallerySlider from '../../javascripts/application/gallery-slider';
import ShareBar from '../../javascripts/application/share-bar';
import EqualHeightCells from '../../javascripts/application/equal-height-cells';
import ExitIntentModals from '../../javascripts/application/exit-intent-modals';
import TableFade from '../../javascripts/application/table-fade';
import FakeUploadInput from '../../javascripts/application/fake-upload-input';
import FocusOutlines from '../../javascripts/application/focus-outlines';
import BuyBackEnquiry from '../../javascripts/application/buy-back-enquiry';
import Countdowns from '../../javascripts/application/countdowns';
import CountUps from '../../javascripts/application/count-ups';
import UspsSlider from '../../javascripts/application/usps-slider';
import TabbedInterface from '../../javascripts/application/tabbed-interface';
import HorizontalScroller from '../../javascripts/application/horizontal-scroller';
import ContainersDeliveredMap from '../../javascripts/application/containers-delivered-map';
import ReviewsSlider from '../../javascripts/application/reviews-slider';
import ToggleTip from '../../javascripts/application/toggletips';
import LogoSlider from '../../javascripts/application/logo-slider';
import CompaniesHouseApi from '../../javascripts/application/companies-house-api';

import '../../stylesheets/application/application.scss';

if (!('object-fit' in document.createElement('a').style)) {
  require('lazysizes/plugins/object-fit/ls.object-fit');
}

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
const images = require.context('../../images/application', true);
const imagePath = (name) => images(name, true);

const companyName = 'Cleveland Containers';

Turbo.session.drive = false;
Rails.start();

window.Stimulus = Application.start();
const context = require.context('../../controllers', true, /\.js$/);
window.Stimulus.load(definitionsFromContext(context));
window.Stimulus.debug = process.env.NODE_ENV === 'development';

ActiveStorage.start();

document.addEventListener('turbo:load', () => {
  new AddUserAgentClasses();
  new Header({
    headerSelector: 'header',
    menuToggleSelector: '[data-js="menu-toggle"]',
    slidingNav: document.body.classList.contains('has-sliding-nav'),
    slidingNavOverlayClasses: ['overlay', 'nav__overlay', 'is-visible'],
    navigationRootListSelector: 'nav[data-js="primary-navigation"] ul',
    childNavToggleSelector: 'button.subnav-toggle-button',
    tabTrappableItemsWrapperSelector: '.page-header__lower-wrapper',
    tabTrappableItemsSelectors: 'a[href]:not(.page-header__logo), button:not([disabled]), [tabindex="0"]',
    translationsSelector: 'nav[data-js="primary-navigation"]',
    translationAttributeForAriaLabelShow: 'data-translation-aria-label-show',
    translationAttributeForAriaLabelHide: 'data-translation-aria-label-hide',
  });
  new Search({
    searchContainerSelector: '[data-search-container]',
    searchButtonsSelector: '[data-search]',
    searchBarSelector: '[data-search-box]',
  });
  new StickyNav({
    header: '.page-header',
    primaryNav: '.nav__primary',
    main: 'main.page-content',
    body: 'body',
  });
  window.gallerySlider = new GallerySlider({
    selector: '[data-js="gallery-slides"]',
  });
  window.googleIdPrefill = () => {
    document.querySelectorAll('[data-fill-gid]').forEach(
      (input) => {
        input.setAttribute(
          'value', ((window.googleClientId === undefined) ? '' : window.googleClientId),
        );
      },
    );
  };
  new ShareBar({
    selector: '[data-share-url]',
    companyName,
  });
  new EqualHeightCells({
    selector: '[data-grid-two-thirds].both-images',
    breakpoint: 768,
  });
  const exitIntentModals = new ExitIntentModals();
  if (document.cookie.search('cc_cookie') !== -1 || window.env !== 'production') {
    exitIntentModals.exitModals();
  }
  new TableFade({
    selector: 'data-js-table-scroll-fade',
  });
  new FakeUploadInput();
  new FocusOutlines();
  new BuyBackEnquiry();
  new Countdowns();
  new UspsSlider({
    selector: '[data-js="usps-slides"]',
  });

  const ti = new TabbedInterface({
    selector: '[data-tabbed-interface-level]',
  });
  ti.initialise();

  new CountUps({
    selector: '[data-count-up-target]',
  });
  new HorizontalScroller({
    selector: '[data-horizontal-scroller]',
  });
  new ContainersDeliveredMap({
    selector: '[data-containers-delivered-section]',
  });
  new ReviewsSlider({
    selector: '[data-js="reviews-slides"]',
  });
  new ToggleTip({
    selector: '[data-toggletip-title]',
  });
  new LogoSlider({
    selector: '[data-js="logo-slides"]',
  });
  new CompaniesHouseApi();
});
